import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Group = ({ children, onChange, active: _defaultActive }) => {
  const [active, setActive] = useState(_defaultActive);

  useEffect(() => {
    setActive(_defaultActive);
  }, [_defaultActive]);

  const handleClick = (index) => {
    setActive((s) => (s === index ? null : index));
    if (onChange) onChange(index);
  };

  return (
    <StyledWrapper>
      {React.Children.toArray(children)
        .filter(React.isValidElement)
        .map((child, index, array) =>
          React.cloneElement(child, {
            ...child.props,
            onClick: () => handleClick(index),
            active: active === index,
            divider: index < array.length - 1,
          })
        )}
    </StyledWrapper>
  );
};

export default Group;
