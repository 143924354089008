import { useFormikContext } from "formik";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Divider, H5, Icon, Info, List } from "../../components";
import { LIST } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const ConfirmPaymentSummary = ({ pricing }) => {
  const { values } = useFormikContext();

  return (
    <>
      <List.Header
        RightComponent={() => (
          <Block display="flex" alignItems="center">
            <Icon.Medium name="lock" color={colors.muted} />
            <Body16
              strong
              color={colors.muted}
              css={`
                margin-left: ${spacings.xs};
              `}
            >
              {polyglot.t("common.secure_payment")}
            </Body16>
          </Block>
        )}
      >
        {polyglot.t("common.summary")}
      </List.Header>
      {pricing.initial_price_unpaid > 0 && (
        <List.Item
          divider={false}
          size={LIST.SIZE.COMPACT}
          RightComponent={() => (
            <Body16>{polyglot.toSmartCurrency(pricing.initial_price_unpaid)}</Body16>
          )}
        >
          <Block display="flex" alignItems="center">
            <List.Elem.Title>{polyglot.t("transaction_details.jobber_price")}</List.Elem.Title>
          </Block>
        </List.Item>
      )}
      <List.Item
        divider={false}
        size={LIST.SIZE.COMPACT}
        RightComponent={() => (
          <Body16>{polyglot.toSmartCurrency(pricing.price)}</Body16>
        )}
      >
        <List.Elem.Title>
          {polyglot.t("common.extra_hours")} (+
          {polyglot.toDuration(values.nb_hours - values.initial_hours, true)})
        </List.Elem.Title>
      </List.Item>
      {pricing.fees > 0 && (
        <List.Item
          divider={false}
          size={LIST.SIZE.COMPACT}
          RightComponent={() => (
            <Body16>{polyglot.toSmartCurrency(pricing.fees)}</Body16>
          )}
        >
          <Block display="flex" alignItems="center">
            <List.Elem.Title>{polyglot.t("common.fees")}</List.Elem.Title>
            <Info>{polyglot.t("common.fees_description")}</Info>
          </Block>
        </List.Item>
      )}
      {pricing.taxes > 0 && (
        <List.Item
          divider={false}
          size={LIST.SIZE.COMPACT}
          RightComponent={() => (
            <List.Elem.Label>
              {polyglot.toSmartCurrency(pricing.taxes)}
            </List.Elem.Label>
          )}
        >
          <Block display="flex" alignItems="center">
            <List.Elem.Title>{polyglot.t("common.taxes")}</List.Elem.Title>
            <Info>{polyglot.t("common.taxes_description")}</Info>
          </Block>
        </List.Item>
      )}
      {pricing.wallet + (pricing.fees_discount || 0) > 0 && (
        <List.Item
          divider={false}
          size={LIST.SIZE.COMPACT}
          RightComponent={() => (
            <Body16 color={colors.success}>
              -
              {polyglot.toSmartCurrency(
                pricing.wallet + (pricing.fees_discount || 0)
              )}
            </Body16>
          )}
        >
          <Block display="flex" alignItems="center">
            <List.Elem.Title>{polyglot.t("wallet.pot")}</List.Elem.Title>
            <Info>{polyglot.t("common.pot_description")}</Info>
          </Block>
        </List.Item>
      )}
      {pricing.cesu > 0 && (
        <List.Item
          divider={false}
          size={LIST.SIZE.COMPACT}
          RightComponent={() => (
            <Body16 color={colors.success}>
              -{polyglot.toSmartCurrency(pricing.cesu)}
            </Body16>
          )}
        >
          <Block display="flex" alignItems="center">
            <List.Elem.Title>{polyglot.t("common.cesu")}</List.Elem.Title>
            <Info>{polyglot.t("common.cesu_description")}</Info>
          </Block>
        </List.Item>
      )}
      <Divider.Cell
        css={`
          margin-top: ${spacings.s};
          margin-bottom: ${spacings.s};
        `}
      />
      <List.Item
        divider={false}
        size={LIST.SIZE.COMPACT}
        RightComponent={() => (
          <H5>{polyglot.toSmartCurrency(pricing.total)}</H5>
        )}
      >
        <H5>{polyglot.t("common.total")}</H5>
      </List.Item>
    </>
  );
};

export default ConfirmPaymentSummary;
