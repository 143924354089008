import { Form, Formik, useFormikContext } from "formik";
import { useMemo, useRef } from "react";
import * as yup from "yup";
import { colors, spacings } from "@/assets/themes";
import {
  Accordion,
  Block,
  Body16,
  Button,
  Container,
  Divider,
  H1,
  H2,
  H4,
  Icon,
  List,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import {
  useGetBookingData,
  useSubmitBooking,
} from "@/modules/routes/jobber-booking-routes";
import polyglot from "@/utils/polyglot";
import PaymentMethodsUpdated from "../common/payment-methods";
import { getPaymentSummaryItems } from "../common/payment-methods/utils/get-payment-summary-items";
import MembershipAdvantages from "../dashboard/account/membership/MembershipAdvantages";
import { getPricing } from "./utils";
import { LIST } from "@/components/Styles/variants";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import { paymentCreditCardValidation, setPaymentInitialValues } from "@/utils";
import { useTokenizeQuery } from "@/modules/routes/payment-routes";
import { useBreakpoints } from "@/modules/hooks";
import Aside from "./Aside";
import Faq from "./Faq";

const SubmitButton = ({ isLoading, data, onSubmit }) => {
  const formikUtils = useFormikContext();
  return (
    <Block
      position="sticky"
      bottom={spacings.m}
      paddingX={spacings.m}
      paddingBottom={spacings.m}
    >
      <PaymentMethodsUpdated.PayButtonEnhancer
        block
        textType="book"
        disabled={!formikUtils.isValid}
        amount={getPricing(formikUtils.values, { ...data }).total}
        lineItems={getPaymentSummaryItems({
          ...getPricing(formikUtils.values, { ...data }),
          voucher_code: formikUtils.values.voucher_code,
        })}
        isLoading={isLoading}
        onPaymentAuthorized={({ token, applePaySession }) =>
          onSubmit(
            {
              ...formikUtils.values,
              token,
              applePaySession,
            },
            formikUtils
          )
        }
      >
        <Button.Large
          block
          type="submit"
          disabled={!formikUtils.isValid}
          isLoading={isLoading}
        >
          {polyglot.t("common.booking")}
        </Button.Large>
      </PaymentMethodsUpdated.PayButtonEnhancer>
    </Block>
  );
};

const JobberBookingPass = ({ jobber }) => {
  const { data } = useGetBookingData();

  const offerWithoutPrice = useMemo(
    () => ({
      ...data.offer,
      price: undefined,
      price_per_hour: undefined,
    }),
    [data.offer]
  );

  const submit = useSubmitBooking();
  const formikRef = useRef();
  const paymentMethodRef = useRef();
  const submitBooking = useTokenizeQuery(submit.mutateAsync);

  const isLoading = submitBooking.isLoading || submitBooking.isSuccess;

  const breakpoints = useBreakpoints();

  const handleSubmit = (values) => {
    submitBooking.mutate(values, {
      onError: (err) => {
        const mustResetCard = err.response.data?.data?.must_reset_card;
        if (mustResetCard) {
          paymentMethodRef.current?.reset();
        }
      },
    });
  };

  const jobData = useMemo(
    () => [
      {
        label: data?.job.title,
        icon: "work",
      },
      {
        label: data?.job.datetime,
        icon: "calendar",
      },
      {
        label: data?.job.address,
        icon: "map-marker",
      },
      {
        label: polyglot.t("booking.hourly_rate", {
          price_per_hour: polyglot.toSmartCurrency(data?.offer.price_per_hour, {
            pricePerHour: true,
            noDecimals: true,
          }),
        }),
        icon: "clock",
      },
    ],
    [data]
  );

  return (
    <div>
      <Container.Medium>
        <Block
          display="grid"
          gridCols={{ xs: "1fr", md: "1fr 350px" }}
          gap={spacings.l}
        >
          <Block marginTop={spacings.m}>
            <Formik
              innerRef={formikRef}
              onSubmit={handleSubmit}
              validationSchema={yup.lazy(() => {
                if (!data.membership.is_active && data.membership.price > 0) {
                  return paymentCreditCardValidation;
                }
                return yup.object().shape({});
              })}
              validateOnMount
              initialValues={{
                ...setPaymentInitialValues({
                  ...data,
                }),
              }}
            >
              <Form>
                <Block marginBottom={spacings.s}>
                  {breakpoints.get({
                    xs: (
                      <H2>
                        {polyglot.t("booking.booking_of_first_name", {
                          first_name: data.offer.jobber.first_name,
                        })}
                      </H2>
                    ),
                    md: (
                      <H1>
                        {polyglot.t("booking.booking_of_first_name", {
                          first_name: data.offer.jobber.first_name,
                        })}
                      </H1>
                    ),
                  })}
                </Block>
                <Block spaceY={spacings.m}>
                  {breakpoints.get({
                    xs: (
                      <JobberOfferItem
                        urlTargetBlank
                        jobber={jobber}
                        hideDetails
                        withGutters
                        shape={LIST.SHAPE.ROUND}
                        css={`
                          background-color: ${colors.background};
                        `}
                      />
                    ),
                    md: null,
                  })}
                  <CardBase flat>
                    <Accordion.Group
                      active={data.membership.is_active ? 0 : undefined}
                    >
                      <Accordion
                        active
                        withGutters
                        divider={false}
                        title={polyglot.t("booking.booking_of_first_name", {
                          first_name: data.offer.jobber.first_name,
                        })}
                        subtitle={data.job.datetime}
                      >
                        <List.Group>
                          {jobData.map((item) => (
                            <List.Item
                              key={item.icon}
                              LeftComponent={() => (
                                <Icon.Large name={item.icon} />
                              )}
                            >
                              <Body16 strong>{item.label}</Body16>
                            </List.Item>
                          ))}
                        </List.Group>
                      </Accordion>
                    </Accordion.Group>
                    {data.membership.is_active && (
                      <SubmitButton
                        isLoading={isLoading}
                        data={data}
                        onSubmit={handleSubmit}
                      />
                    )}
                  </CardBase>
                </Block>
                <Block>
                  {!data.membership.is_active && (
                    <Block>
                      <List.Header
                        subtitle={polyglot.t(
                          "membership.subscription_no_obligation"
                        )}
                        RightComponent={() => (
                          <H4>
                            {polyglot.toSmartCurrency(data.membership.price)}
                          </H4>
                        )}
                      >
                        <span>Pass 1 mois</span>
                        <Body16
                          css={`
                            font-weight: var(--font-weight-regular);
                          `}
                        >
                          {polyglot.t("membership.subscription_no_obligation")}
                        </Body16>
                      </List.Header>
                      <CardBase flat>
                        <Block paddingY={spacings.s}>
                          <MembershipAdvantages withGutters />
                          <Block marginX={spacings.m}>
                            <Divider.Cell />
                          </Block>
                          <PaymentMethodsUpdated
                            ref={paymentMethodRef}
                            withGutters
                            divider={false}
                            mangopay_cards={data.mangopay_cards}
                            methods={data.payment_methods}
                          />
                        </Block>
                        <SubmitButton
                          isLoading={isLoading}
                          data={data}
                          onSubmit={handleSubmit}
                        />
                      </CardBase>
                    </Block>
                  )}

                  {data?.zendesk_articles?.length > 0 && (
                    <Block marginTop={spacings.m}>
                      <CardBase flat>
                        <Block
                          paddingBottom={spacings.sm}
                          paddingTop={{ xs: spacings.xs, md: spacings.sm }}
                        >
                          <Block paddingX={{ xs: spacings.m, md: spacings.ml }}>
                            <Faq data={data?.zendesk_articles} />
                          </Block>
                        </Block>
                      </CardBase>
                    </Block>
                  )}
                </Block>
              </Form>
            </Formik>
          </Block>

          <Block>
            {breakpoints.get({
              md: (
                <Block>
                  {!data.extended_cover_eligible ? (
                    <>
                      {/* Fix visual bloc alignement */}
                      <List.Header>&nbsp;</List.Header>
                    </>
                  ) : (
                    <Block height={spacings.ml} />
                  )}
                  <Aside
                    job={data.job}
                    jobber={jobber}
                    offer={offerWithoutPrice}
                    show_phone_assistance={data.show_phone_assistance}
                    hideSummary
                  />
                </Block>
              ),
            })}
          </Block>
        </Block>
      </Container.Medium>
    </div>
  );
};

export default JobberBookingPass;
