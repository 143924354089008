import React from "react";
import { colors, sizes, spacings } from "../../../assets/themes";
import { Block, Skeleton } from "../../../components";
import { SHAPE } from "../../../components/Styles/variants";

const MembershipSkeleton = () => (
  <Block display="grid" gridCols={{ xs: 1, lg: "1fr 350px" }} gap={spacings.l}>
    {/* Main content */}
    <Block>
      <Skeleton.Group>
        <Block spaceY={spacings.m}>
          {/* Icon circle skeleton */}
          <Skeleton
            height={sizes.size64}
            width={sizes.size64}
            shape={SHAPE.SHAPE.CIRCLE}
            sr
          />

          {/* Title and price section */}
          <Block spaceY={spacings.sm}>
            <Skeleton height={sizes.size32} width="60%" sb />
            <Skeleton height={sizes.size24} width="40%" />
          </Block>
        </Block>

        {/* Advantages section */}
        <Block marginY={spacings.l}>
          <Skeleton height={sizes.size24} width="40%" sb />
          <Block marginY={spacings.m}>
            <Skeleton height={sizes.size48} width="100%" rows={4} />
          </Block>
        </Block>

        {/* Button section */}
        <Block marginY={spacings.m}>
          <Skeleton height={sizes.size48} width="100%" />
        </Block>
      </Skeleton.Group>
    </Block>

    {/* Help section */}
    <Block>
      <Skeleton.Group>
        <Block spaceY={spacings.m}>
          <Skeleton height={sizes.size24} width="60%" sb />
          <Skeleton height={sizes.size48} width="100%" rows={3} />
        </Block>
      </Skeleton.Group>
    </Block>
  </Block>
);

export default MembershipSkeleton;
