import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { colors, spacings } from "@/assets/themes";
import {
  Block,
  Body18,
  Button,
  ConditionnalWrapper,
  Icon,
  Shape,
} from "@/components";
import { BUTTON, SHAPE } from "@/components/Styles/variants";
import { useAskService, useBreakpoints } from "@/modules/hooks";
import { useGetMemberships } from "@/modules/routes/dashboard-routes";
import polyglot from "@/utils/polyglot";
import { ACCOUNT_MEMBERSHIP_MANAGE_PATH } from "../../config/routes";
import PageTitle from "../../PageTitle";
import MembershipAdvantages from "./MembershipAdvantages";
import MembershipAlerts from "./MembershipAlerts";
import MembershipLayout from "./MembershipLayout";
import { MEMBERSHIP_STATES } from "@/utils";
import { CardBase } from "@/components/Styles/Base";

const PassLanding = ({ config }) => {
  const { show } = useAskService();
  const { data } = useGetMemberships();
  const breakpoints = useBreakpoints();
  return (
    <MembershipLayout>
      <Block spaceY={spacings.m}>
        <Block spaceY={spacings.m}>
          <Shape.Large
            backgroundColor={colors.primary}
            shape={SHAPE.SHAPE.CIRCLE}
          >
            <Icon.XLarge name="tag-solid" color={colors.onColor} />
          </Shape.Large>
          <Block spaceY={spacings.sm}>
            <Block>
              <PageTitle>{config.title}</PageTitle>
              <Body18>
                {polyglot.t("pass.price_no_obligation", {
                  price: polyglot.toSmartCurrency(
                    data?.membership?.price || data?.price
                  ),
                })}
              </Body18>
            </Block>
            {data?.membership && (
              <MembershipAlerts state={data?.membership?.state} />
            )}
          </Block>
        </Block>
        <ConditionnalWrapper
          condition={breakpoints.get({ xs: false, lg: true })}
          wrapper={(children) => (
            <CardBase
              flat
              light
              css={`
                overflow: hidden;
                padding-top: ${spacings.s};
                padding-bottom: ${spacings.s};
              `}
            >
              {children}
            </CardBase>
          )}
        >
          <MembershipAdvantages
            withGutters={breakpoints.get({ xs: false, lg: true })}
          />
        </ConditionnalWrapper>

        <Block display="flex" gap={spacings.s} flexDirection="column">
          {data?.membership &&
          data?.membership?.state !== MEMBERSHIP_STATES.CANCELED ? (
            <Button.Large
              kind={BUTTON.KIND.SECONDARY}
              as={NavLink}
              to={ACCOUNT_MEMBERSHIP_MANAGE_PATH}
            >
              {polyglot.t("membership.manage")}
            </Button.Large>
          ) : (
            <Button.Large onClick={show}>
              {polyglot.t("jobs.ask_service")}
            </Button.Large>
          )}
        </Block>
      </Block>
    </MembershipLayout>
  );
};

export default PassLanding;
