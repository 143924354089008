import { Form, Formik } from "formik";
import { useRef } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { radius, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Body16,
  Breadcrumb,
  Button,
  Icon,
  Link,
  List,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import {
  useGetMemberships,
  usePutMembershipUpdateCreditCard,
} from "@/modules/routes/dashboard-routes";
import { useTokenizeQuery } from "@/modules/routes/payment-routes";
import PaymentMethodsUpdated from "@/pages/common/payment-methods";
import {
  ACCOUNT_MEMBERSHIP_CANCEL_PATH,
  ACCOUNT_MEMBERSHIP_PATH,
} from "@/pages/dashboard/config/routes";
import PageTitle from "@/pages/dashboard/PageTitle";
import {
  formatDate,
  paymentCreditCardValidation,
  setPaymentInitialValues,
} from "@/utils";
import { getCreditCardTypeByName } from "@/utils/payment";
import polyglot from "@/utils/polyglot";
import MembershipAlerts from "../MembershipAlerts";
import MembershipLayout from "../MembershipLayout";

const ManageMembership = () => {
  const { data } = useGetMemberships();
  const paymentMethodRef = useRef();

  const putMembershipUpdateCreditCard = usePutMembershipUpdateCreditCard();
  const submitCreditCard = useTokenizeQuery(
    putMembershipUpdateCreditCard.mutate
  );

  const handleSubmit = (values) => {
    submitCreditCard.mutate(values, {
      onError: (err) => {
        const mustResetCard = err.response.data?.data?.must_reset_card;
        if (mustResetCard) {
          paymentMethodRef.current?.reset();
        }
      },
    });
  };

  return (
    <MembershipLayout>
      <Formik
        enableReinitialize
        initialValues={{
          ...setPaymentInitialValues({
            ...data,
          }),
          mangopay_card_id: data?.membership?.mangopay_card_id,
          payment_method: getCreditCardTypeByName(
            data?.mangopay_cards.find(
              (card) => card.id === data?.membership?.mangopay_card_id
            )?.kind || ""
          ),
        }}
        validationSchema={paymentCreditCardValidation}
        onSubmit={handleSubmit}
      >
        {({ values, isValid }) => (
          <Form>
            <Block marginBottom={spacings.m}>
              <Breadcrumb>
                <Link to={ACCOUNT_MEMBERSHIP_PATH}>
                  {polyglot.t("common.subscription")}
                </Link>
                <span>{polyglot.t("membership.manage")}</span>
              </Breadcrumb>
            </Block>
            <PageTitle>{polyglot.t("membership.manage")}</PageTitle>
            <List.Header>
              {polyglot.t("membership.your_subscription")}
            </List.Header>
            <CardBase
              flat
              light
              css={`
                overflow: hidden;
                padding: ${spacings.s} 0;
              `}
            >
              <Block marginX={spacings.s}>
                <MembershipAlerts
                  disableRedirect
                  state={data?.membership?.state}
                  css={`
                    border-radius: ${radius.m};
                  `}
                />
              </Block>
              <List.Item
                withGutters
                LeftComponent={() => <Icon.Large name="credit-card" />}
                RightComponent={() => (
                  <Body16 strong>
                    {polyglot.toSmartCurrency(data?.price)}
                  </Body16>
                )}
              >
                <Body16 strong>{polyglot.t("membership.next_payment")}</Body16>
                <Body14>
                  {polyglot.t("membership.renewal_date", {
                    date: formatDate(
                      data?.membership?.renewal_date,
                      "DD MMMM YYYY"
                    ),
                  })}
                </Body14>
              </List.Item>
              <PaymentMethodsUpdated
                ref={paymentMethodRef}
                mangopay_cards={data?.mangopay_cards}
                methods={data?.payment_methods}
                withGutters
                divider={false}
              />
              {data?.membership?.mangopay_card_id !==
                values.mangopay_card_id && (
                <Block marginX={spacings.m} marginBottom={spacings.s}>
                  <Button.Medium
                    block
                    type="submit"
                    disabled={!isValid}
                    isLoading={
                      submitCreditCard.isLoading ||
                      putMembershipUpdateCreditCard.isLoading ||
                      putMembershipUpdateCreditCard.data?.data?.data
                        ?.redirect_url
                    }
                  >
                    {polyglot.t("common.update")}
                  </Button.Medium>
                </Block>
              )}
            </CardBase>
            <List.Header>{polyglot.t("membership.manage")}</List.Header>
            <CardBase
              flat
              light
              css={`
                overflow: hidden;
                padding: ${spacings.s} 0;
              `}
            >
              <List.Group>
                <List.Item
                  withGutters
                  as={NavLink}
                  LeftComponent={() => <Icon.Large name="times-circle" />}
                  to={ACCOUNT_MEMBERSHIP_CANCEL_PATH}
                >
                  <Body16>{polyglot.t("membership.cancel")}</Body16>
                </List.Item>
              </List.Group>
            </CardBase>
          </Form>
        )}
      </Formik>
    </MembershipLayout>
  );
};

export default ManageMembership;
