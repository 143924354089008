import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MEMBERSHIP_QUERY_KEY, MEMBERSHIP_STATES } from "@/utils";
import {
  ACCOUNT_MEMBERSHIP_CANCEL_PATH,
  ACCOUNT_MEMBERSHIP_MANAGE_PATH,
  ACCOUNT_MEMBERSHIP_PATH,
} from "@/pages/dashboard/config/routes";

const useMembershipRedirect = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([MEMBERSHIP_QUERY_KEY]);
  const history = useHistory();
  const currentPath = history.location.pathname;

  const isProtectedPath =
    currentPath === ACCOUNT_MEMBERSHIP_MANAGE_PATH ||
    currentPath === ACCOUNT_MEMBERSHIP_CANCEL_PATH;
  const hasCanceledOrNoMembership =
    !data?.membership || data?.membership?.state === MEMBERSHIP_STATES.CANCELED;

  const shouldRedirect = isProtectedPath && data && hasCanceledOrNoMembership;

  const renderContent = useCallback(
    (children) => {
      if (shouldRedirect) {
        return <Redirect to={ACCOUNT_MEMBERSHIP_PATH} />;
      }
      if (data) {
        return children;
      }
      return null;
    },
    [data, shouldRedirect]
  );

  return { renderContent };
};

export default useMembershipRedirect;
