import { useContext } from "react";
import { useSelector } from "react-redux";
import { DashboardContext } from "../contexts";
import { BADGE, ICON } from "@/components/Styles/variants";
import { colors } from "@/assets/themes";
import polyglot from "@/utils/polyglot";
import { Badge, Icon, List } from "@/components";
import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
  INBOXES_PATH,
  REFERRAL_PATH,
  TICKETS_PATH,
} from "@/pages/dashboard/config/routes";
import Tickets from "@/pages/dashboard/Tickets";
import { Content, Full } from "@/pages/dashboard/Layouts";
import { getReferralMenuClickEvent } from "@/utils/analytics-events";
import Referral from "@/pages/dashboard/Referral";
import Home from "@/pages/dashboard/Home";
import Account from "@/pages/dashboard/Account";
import Inboxes from "@/pages/dashboard/Inboxes";
import Jobs from "@/pages/dashboard/Jobs";
import { useGetDashboard } from "../routes/dashboard-routes";

const useMenu = (props = {}) => {
  const { iconSize = ICON.SIZE.XLARGE } = props;
  const badgesData = useSelector((state) => state.badges);
  const { data: badges } = useGetDashboard();
  const { public_pages } = useContext(DashboardContext);

  const data = {
    ...badgesData,
    ...badges,
  };

  const main = [
    {
      path: HOME_PATH,
      title: polyglot.t("routes.home"),
      exact: true,
      LeftComponent: (
        <Badge
          position={BADGE.POSITION.TOP_RIGHT}
          count={data?.notifications_count}
        >
          <Icon.XLarge size={iconSize} name="home" color={colors.primary} />
        </Badge>
      ),
      View: Home,
      Layout: Full,
    },
    {
      path: DASHBOARD_PATH,
      title: polyglot.t("routes.jobs"),
      exact: true,
      LeftComponent: (
        <Icon.XLarge size={iconSize} name="bookmark" color={colors.primary} />
      ),
      View: Jobs,
      RightComponent: !!data?.jobs_count && data?.jobs_count > 0 && (
        <List.Elem.Label>
          {polyglot.t("jobs.n_active_plural", { count: data.jobs_count })}
        </List.Elem.Label>
      ),
      Layout: Content,
    },
    {
      path: INBOXES_PATH,
      title: polyglot.t("routes.conversations"),
      exact: false,
      LeftComponent: (
        <Badge position={BADGE.POSITION.TOP_RIGHT} count={data?.messages_count}>
          <Icon.XLarge size={iconSize} name="chat" color={colors.primary} />
        </Badge>
      ),
      View: Inboxes,
      Layout: Full,
    },
    {
      path: ACCOUNT_PATH,
      title: polyglot.t("routes.account"),
      exact: true,
      LeftComponent: (
        <Icon.XLarge size={iconSize} name="user" color={colors.primary} />
      ),
      RightComponent: !!data?.wallet_amount &&
        !Number.isNaN(data?.wallet_amount) && (
          <List.Elem.Label>
            {polyglot.toSmartCurrency(data?.wallet_amount || 0)}
          </List.Elem.Label>
        ),
      View: Account,
      Layout: Content,
    },
  ];

  // Get referral menu item
  const referral = data.referral_available
    ? {
        path: REFERRAL_PATH,
        title: polyglot.t("routes.referral"),
        LeftComponent: (
          <Icon.XLarge
            size={iconSize}
            name="user-plus"
            color={colors.primary}
          />
        ),
        subtitle: polyglot.t("routes.referral_description"),
        exact: true,
        event: getReferralMenuClickEvent(),
        View: Referral,
        Layout: Content,
      }
    : null;

  // Get dashboard alt menu
  const alt = [
    {
      path: TICKETS_PATH,
      title: polyglot.t("routes.tickets"),
      exact: false,
      RightComponent: <Badge count={data?.tickets_count} />,
      View: Tickets,
      Layout: Full,
    },
    {
      href: public_pages?.faq,
      title: polyglot.t("routes.help_center"),
    },
  ];

  return {
    main,
    referral,
    alt,
  };
};

export default useMenu;
