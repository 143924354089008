import { useQueryClient } from "@tanstack/react-query";
import { colors } from "@/assets/themes";
import {
  Alert,
  Body14,
  Body16,
  Button,
  ConditionnalWrapper,
  Icon,
  Link,
  List,
} from "@/components";
import { ALERT, BUTTON, LIST } from "@/components/Styles/variants";
import { usePutMembershipResume } from "@/modules/routes/dashboard-routes";
import { formatDate, MEMBERSHIP_QUERY_KEY, MEMBERSHIP_STATES } from "@/utils";
import {
  ACCOUNT_MEMBERSHIP_MANAGE_PATH,
  ACCOUNT_PATH,
} from "../../config/routes";
import polyglot from "@/utils/polyglot";

const MembershipAlerts = ({ state, className, disableRedirect = false }) => {
  const { mutate: resumeMembership, isLoading } = usePutMembershipResume();
  const queryClient = useQueryClient();
  const data =
    queryClient.getQueryData([MEMBERSHIP_QUERY_KEY]) ||
    queryClient.getQueryData([ACCOUNT_PATH]);

  if (state === MEMBERSHIP_STATES.SUSPENDED) {
    return (
      <ConditionnalWrapper
        condition={!disableRedirect}
        wrapper={(children) => (
          <Link
            to={ACCOUNT_MEMBERSHIP_MANAGE_PATH}
            unstyled
            css={`
              display: block;
            `}
          >
            {children}
          </Link>
        )}
      >
        <Alert.Low
          kind={ALERT.KIND.DANGER}
          className={className}
          chevron={!disableRedirect}
        >
          <Body16 strong>{polyglot.t("membership.payment_error")}</Body16>
          <Body14
            css={`
              font-weight: var(--font-weight-regular);
            `}
          >
            {polyglot.t("membership.payment_error_description")}
          </Body14>
        </Alert.Low>
      </ConditionnalWrapper>
    );
  }
  if (state === MEMBERSHIP_STATES.CANCELED) {
    return (
      <List.Item
        css={`
          background-color: ${colors.primary};
          color: ${colors.onColor};
        `}
        className={className}
        shape={LIST.SHAPE.ROUND}
        divider={false}
        withGutters
        LeftComponent={() => <Icon.Large name="calendar-times-solid" />}
        RightComponent={() => (
          <Button.Small
            shape={BUTTON.SHAPE.PILL}
            kind={BUTTON.KIND.MINIMAL}
            css={`
              background: rgba(255, 255, 255, 0.3);
              color: ${colors.onColor} !important;
              &:hover {
                background: rgba(255, 255, 255, 0.4) !important;
              }
            `}
            onClick={resumeMembership}
            isLoading={isLoading}
          >
            {polyglot.t("common.renew")}
          </Button.Small>
        )}
      >
        <Body16>
          {polyglot.t("membership.membership_will_be_canceled_on", {
            date: formatDate(
              data?.membership.renewal_date,
              "DD MMMM YYYY",
              "YYYY-MM-DD"
            ),
          })}
        </Body16>
      </List.Item>
    );
  }
  return null;
};

export default MembershipAlerts;
