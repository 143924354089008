import { useState } from "react";
import { spacings } from "@/assets/themes";
import { Block, Breadcrumb, Link } from "@/components";
import {
  ACCOUNT_MEMBERSHIP_MANAGE_PATH,
  ACCOUNT_MEMBERSHIP_PATH,
} from "@/pages/dashboard/config/routes";
import MembershipLayout from "../MembershipLayout";
import Confirm from "./Confirm";
import PreConfirm from "./PreConfirm";

const CancelMembership = () => {
  const [currState, setCurrState] = useState("preConfirm");

  const renderContent = () => {
    switch (currState) {
      case "confirm":
        return <Confirm />;
      default:
        return <PreConfirm goNext={() => setCurrState("confirm")} />;
    }
  };

  return (
    <MembershipLayout>
      <Block marginBottom={spacings.m}>
        <Breadcrumb>
          <Link to={ACCOUNT_MEMBERSHIP_PATH}>Abonnement</Link>
          <Link to={ACCOUNT_MEMBERSHIP_MANAGE_PATH}>Gérer mon abonnement</Link>
          <Block as="span">Confirmation</Block>
        </Breadcrumb>
      </Block>
      {renderContent()}
    </MembershipLayout>
  );
};

export default CancelMembership;
