import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useToast from "./useToast";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";

export default function useFetch(query, options = {}) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const key = options.queryKey || [query];
  const res = useQuery(
    key,
    async () => {
      const { data } = await api.get(query, { params: options.params });
      if (options.apiBackwardCompatibility) {
        return data;
      }
      return data.data;
    },
    options
  );
  useEffect(() => {
    if (res.isError && !options?.shadow)
      toast.danger(polyglot.t("common.errors.get"));
  }, [res.isError]);

  return {
    ...res,
    isLoading: !queryClient.getQueryData(key)
      ? res.isLoading || !res.data
      : false,
    key: query,
    data: queryClient.getQueryData(key) || res.data,
  };
}
