import { Body16, Button, Modal } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import ModalSupportFooter from "./ModalSupportFooter";

const getModalSubtitle = (nbHours, refund_disabled) => {
  if (!nbHours) {
    return polyglot.t("cancel_review.job_will_be_close");
  }
  if (refund_disabled) {
    return polyglot.t("cancel_review.job_will_be_close_alt_no_refund");
  }
  return polyglot.t("cancel_review.job_will_be_close_alt");
};

const CancelConfirmModal = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  nbHours,
  refund_disabled,
}) => (
  <Modal.XSmall onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>
        {polyglot.t("cancel_review.cancel_definitively")}
      </Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <Body16>{getModalSubtitle(nbHours, refund_disabled)}</Body16>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large
        onClick={onSubmit}
        accentColor={BUTTON.ACCENT_COLOR.DANGER}
        isLoading={isLoading}
        block
      >
        {polyglot.t("cancel_review.confirm_cancel")}
      </Button.Large>
      <Button.Large kind={BUTTON.KIND.MINIMAL} onClick={onClose} block>
        {polyglot.t("common.cancel")}
      </Button.Large>
    </Modal.Item.Footer>
    <ModalSupportFooter />
  </Modal.XSmall>
);

export default CancelConfirmModal;
