import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Divider, List } from "../../components";
import { ICON, LIST } from "../../components/Styles/variants";
import { useGoogleAnalytics } from "../../modules/hooks";
import useMenu from "../../modules/hooks/useMenu";
import { useLogout } from "../../modules/routes/dashboard-routes";
import polyglot from "../../utils/polyglot";

const MenuItem = ({
  href,
  LeftComponent,
  RightComponent,
  title,
  renderTitle,
  subtitle,
  path,
  strong,
  onClick,
}) => (
  <List.Item
    withGutters
    href={path || href}
    divider={false}
    size={LIST.SIZE.DEFAULT}
    LeftComponent={LeftComponent ? () => LeftComponent : null}
    RightComponent={RightComponent ? () => RightComponent : null}
    onClick={onClick}
  >
    <List.Elem.Title strong={strong} numberOfLines={1}>
      {renderTitle ? renderTitle() : title}
    </List.Elem.Title>
    {subtitle && <Body14 color={colors.muted}>{subtitle}</Body14>}
  </List.Item>
);

const MenuItems = () => {
  const logout = useLogout();
  const { sendEvent } = useGoogleAnalytics();
  const { main, referral, alt } = useMenu({ iconSize: ICON.SIZE.LARGE });
  return (
    <div>
      {main.map((item, i) => (
        <MenuItem {...item} t key={`header-menu-item-main-${i}`} strong />
      ))}
      {referral && (
        <>
          <Block marginY={spacings.xs}>
            <Divider.Cell />
          </Block>
          <MenuItem
            {...referral}
            onClick={() => sendEvent(referral.event)}
            strong
          />
        </>
      )}
      <Block marginY={spacings.xs}>
        <Divider.Cell />
      </Block>
      {alt.map((item, i) => (
        <MenuItem {...item} key={`header-menu-alt-item-${i}`} />
      ))}
      <List.Item divider={false} withGutters onClick={() => logout.mutate()}>
        <List.Elem.Title>{polyglot.t("common.logout")}</List.Elem.Title>
      </List.Item>
    </div>
  );
};

export default MenuItems;
