import { Form, Formik } from "formik";
import { useEffect, useLayoutEffect, useRef } from "react";
import * as yup from "yup";
import { spacings } from "../../assets/themes";
import { Block } from "../../components";
import { useConfirmPayment } from "../../modules/routes/job-routes";
import { useTokenizeQuery } from "../../modules/routes/payment-routes";
import {
  paymentCreditCardValidation,
  setPaymentInitialValues,
} from "../../utils";
import PaymentMethodsUpdated from "../common/payment-methods";
import ConfirmActions from "./ConfirmActions";
import ConfirmForm from "./ConfirmForm";
import ConfirmPaymentSummary from "./ConfirmPaymentSummary";
import ReviewFooter from "./ReviewFooter";

const ConfirmPayment = ({ offer_id, goNext, data }) => {
  const formikRef = useRef();
  const paymentMethodRef = useRef();
  const confirm = useConfirmPayment();
  const confirmPayment = useTokenizeQuery(confirm.mutateAsync);

  const handleSubmit = (values) => {
    confirmPayment.mutate({
      ...values,
      nb_hours: values.nb_hours - values.initial_hours,
      id: offer_id,
    });
  };

  useLayoutEffect(() => {
    if (confirmPayment.isSuccess) {
      if (!confirmPayment.data.data.data.secure_url) {
        goNext();
      }
    }
    if (confirmPayment.isError) {
      const mustResetCard = confirmPayment.error.data?.data?.must_reset_card;
      if (mustResetCard) {
        paymentMethodRef.current?.reset();
      }
    }
  }, [confirmPayment.isError, confirmPayment.isSuccess, formikRef.current]);

  const getPricing = (values) => {
    const totalHours = values.nb_hours;
    return data.pricing.find((p) => p.nb_hours_total === totalHours);
  };

  return (
    <div>
      <Formik
        validateOnMount
        innerRef={formikRef}
        validationSchema={yup.lazy((values) => {
          if (getPricing(values).total > 0) {
            return paymentCreditCardValidation;
          }
          return yup.object().shape({});
        })}
        initialValues={{
          initial_hours: data.offer.nb_hours,
          nb_hours: data.offer.extra_hours + data.offer.nb_hours,
          ...setPaymentInitialValues({
            card_number: data.card_number,
            card_type: data.card_type,
            payment_method: data.default_payment_method,
            default_payment_method: data.default_payment_method,
          }),
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form id="confirm-payment-form">
            <ConfirmForm
              jobber={data.offer.jobber}
              pricings={data.pricing}
              price_per_hour={data.offer.extra_price_per_hour}
              pricing={getPricing(values)}
            />
            {(!!(values.nb_hours - values.initial_hours) || getPricing(values).initial_price_unpaid > 0) && (
              <>
                <ConfirmPaymentSummary
                  wallet_fees={data.wallet_fees}
                  pricing={getPricing(values)}
                />
                {getPricing(values).total > 0 && (
                  <PaymentMethodsUpdated
                    mangopay_cards={data.mangopay_cards}
                    methods={data.payment_methods}
                    ref={paymentMethodRef}
                  />
                )}
              </>
            )}
            <ConfirmActions
              pricing={getPricing(values)}
              jobber={data.offer.jobber}
              job={data.job}
              handleSubmit={handleSubmit}
              isLoading={confirmPayment.isLoading || confirmPayment.isSuccess}
            />
          </Form>
        )}
      </Formik>
      <Block marginTop={spacings.l}>
        <ReviewFooter />
      </Block>
    </div>
  );
};

export default ConfirmPayment;
