import { useSelector } from "react-redux";
import { sizes } from "../assets/themes";
import { Block, HistoryScrollToTop } from "../components";
import { useWindowSize } from "../modules/hooks";
import { BREAKPOINTS } from "../utils";

import BottomNav from "./dashboard/BottomNav";
import Menu from "./dashboard/Menu";

const BOTTOM_NAV_HEIGHT = `calc(${sizes.size64} + env(safe-area-inset-bottom))`;

const DashboardWrapper = ({ children }) => {
  const badges = useSelector((state) => state.badges);
  const { width } = useWindowSize();

  return (
    <Block
      minHeight="100%"
      display="flex"
      alignItems="stretch"
      marginBottom={{ xs: BOTTOM_NAV_HEIGHT, md: 0 }}
    >
      <HistoryScrollToTop />
      <Block
        width="300px"
        display={{ xs: "none", md: "flex" }}
        position="fixed"
        height="100%"
        zIndex="9"
        top="var(--nav-height)"
      >
        <Menu />
      </Block>
      {width <= BREAKPOINTS.md && <BottomNav data={badges} />}
      <Block
        width={{ xs: "100%", md: "calc(100% - 300px)" }}
        marginLeft={{ xs: 0, md: "300px" }}
      >
        {children}
      </Block>
    </Block>
  );
};

export default DashboardWrapper;
