import { AnimatePresence, motion } from "framer-motion";
import styled, { css } from "styled-components";
import { borderWidth, colors, sizes, spacings } from "../../assets/themes";
import Block from "../Block";
import Icon from "../Icon";
import { Body14, Body16 } from "../Text";

const EXPENDED = "expended";
const COLLAPSED = "collapsed";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ divider }) =>
    divider ? `solid ${borderWidth.s} ${colors.border}` : null};
`;

const StyledHeader = styled.button`
  ${({ withGutters }) => css`
    padding-left: ${withGutters ? spacings.m : 0};
    padding-right: ${withGutters ? spacings.m : 0};
    padding-top: ${spacings.m};
    padding-bottom: ${spacings.m};
  `}
  background: none;
  border: none;
  color: ${colors.body};
  display: flex;
  justify-content: space-between;
  min-height: ${sizes.size54};
  flex-direction: row;
  align-items: center;
  text-align: left;
  position: relative;
  cursor: pointer !important;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }
  &:active {
    position: relative;
    &:after {
      background-color: ${colors.overlayDark};
      transition: background-color 0.1s;
    }
  }
`;

const StyledChildrenContent = styled(motion.div)`
  ${({ withGutters }) => css`
    padding-left: ${withGutters ? spacings.m : 0};
    padding-right: ${withGutters ? spacings.m : 0};
  `}
  overflow: hidden;
`;

const StyledRightComponent = styled(motion.div)`
  margin-left: ${spacings.m};
`;

const transition = { ease: "easeInOut", duration: 0.2 };

const chevronVariants = {
  [EXPENDED]: { rotate: 180, transition },
  [COLLAPSED]: { rotate: 0, transition },
};

const contentVariants = {
  [EXPENDED]: { opacity: 1, height: "auto", transition },
  [COLLAPSED]: { opacity: 0, height: 0, transition },
};

const Accordion = ({
  withGutters,
  onClick,
  children,
  active,
  title,
  subtitle,
  divider = true,
}) => {
  const motionValue = active ? EXPENDED : COLLAPSED;

  return (
    <StyledWrapper divider={divider}>
      <StyledHeader type="button" withGutters={withGutters} onClick={onClick}>
        <Block>
          {typeof title === "function" ? (
            title()
          ) : (
            <Body16 strong>{title}</Body16>
          )}
          {typeof subtitle === "function" ? (
            subtitle()
          ) : (
            <Body14 color={colors.muted}>{subtitle}</Body14>
          )}
        </Block>
        <StyledRightComponent
          variants={chevronVariants}
          initial={motionValue}
          animate={motionValue}
        >
          <Icon.Medium name="angle-down" color={colors.muted} />
        </StyledRightComponent>
      </StyledHeader>
      <AnimatePresence exitBeforeEnter initial={false}>
        {active && (
          <StyledChildrenContent
            withGutters={withGutters}
            initial={COLLAPSED}
            animate={EXPENDED}
            exit={COLLAPSED}
            variants={contentVariants}
            transtion={transition}
          >
            <Block marginBottom={spacings.m}>{children}</Block>
          </StyledChildrenContent>
        )}
      </AnimatePresence>
    </StyledWrapper>
  );
};

export default Accordion;
