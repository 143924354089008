import { sizes, spacings } from "../../assets/themes";
import { Avatar, Block, H3, Modal } from "../../components";
import MenuItems from "./MenuItems";

const MobileMenu = ({ user, isOpen, onClose }) => (
  <Modal.FullScreen isOpen={isOpen} hideBackdrop>
    <Modal.Item.Header onClose={onClose} />
    <Block marginX={spacings.m}>
      <Block display="flex" alignItems="center" justifyContent="space-between">
        <H3
          strong
          css={`
            margin-right: ${spacings.s};
            word-wrap: break-word;
          `}
        >
          Bonjour {user.first_name}
        </H3>
        <Avatar src={user.avatar} size={sizes.size48} name={user.first_name} />
      </Block>
    </Block>
    <MenuItems />
  </Modal.FullScreen>
);

export default MobileMenu;
