import { useFormikContext } from "formik";
// eslint-disable-next-line import/no-unresolved
import Short from "@/assets/images/nb_hours_selection/Short.svg";
// eslint-disable-next-line import/no-unresolved
import Medium from "@/assets/images/nb_hours_selection/Medium.svg";
// eslint-disable-next-line import/no-unresolved
import Large from "@/assets/images/nb_hours_selection/Large.svg";
import { sizes, spacings } from "../../../assets/themes";
import { Block, Divider, H3, H4, Icon, Tag } from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import { STEPPER, TAG } from "../../../components/Styles/variants";
import { useBreakpoints } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import { MAX_NB_HOURS, MIN_NB_HOURS } from "../constants";
import useCategoryValue from "../useCategoryValue";
import {
  FormCardSelectables,
  FormLargeStepper,
  StyledFormControl,
} from "./fields";
import StepTitle from "./StepTitle";

const SelectableArtwork = ({ value, src }) => {
  const breakpoints = useBreakpoints();
  return (
    <Block
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <Block marginBottom={spacings.s}>
        <img src={src} alt="" width={sizes.size36} height={sizes.size36} />
      </Block>
      {breakpoints.get({
        xs: <H4 align="center">{polyglot.toDuration(value, true)}</H4>,
        sm: <H3 align="center">{polyglot.toDuration(value, true)}</H3>,
      })}
    </Block>
  );
};

const renderOptions = (options) =>
  options.map((option, index) => {
    const newOption = { ...option };
    if (option.popular) {
      newOption.recommended = () => (
        <Tag.Small
          kind={TAG.KIND.WARNING}
          LeftComponent={() => <Icon.Small name="star-solid" />}
        >
          {polyglot.t("common.popular")}
        </Tag.Small>
      );
    }
    return {
      ...newOption,
      image: () => (
        <SelectableArtwork
          value={option.value}
          src={[Short, Medium, Large][index]}
        />
      ),
    };
  });

const NbHoursSelection = ({
  title,
  children,
  options: _options = [
    {
      label: polyglot.t("categories.short"),
      value: 1.5,
    },
    {
      label: polyglot.t("categories.classic"),
      value: 3,
      popular: true,
    },
    {
      label: polyglot.t("categories.long"),
      value: 4.5,
    },
  ],
}) => {
  const { values, _propsInitialValues, setFieldValue } = useFormikContext();
  const { getNbHours } = useCategoryValue();
  const recommendedNbHours = _propsInitialValues?.nb_hours || getNbHours();
  const breakpoints = useBreakpoints();

  const handleChange = (value) => {
    setFieldValue("nb_hours", value);
  };

  return (
    <>
      <StepTitle>
        {title ||
          polyglot.t("categories.estimated_time_duration_suits_for_you", {
            time: polyglot.toDuration(recommendedNbHours, true),
          })}
      </StepTitle>
      {children}
      <StyledFormControl>
        <FormCardSelectables
          name="nb_hours"
          scrollable={breakpoints.get({ xs: true, sm: false })}
          onChange={handleChange}
          options={renderOptions(_options)}
        />
      </StyledFormControl>
      <Divider.Cell>{polyglot.t("common.or")}</Divider.Cell>
      <StyledFormControl
        label={polyglot.t("categories.adjust_manually_nb_hours")}
        css={`
          text-align: center;
        `}
      >
        <CardBase
          css={`
            display: inline-flex;
            padding: ${spacings.m};
          `}
        >
          <FormLargeStepper
            size={STEPPER.SIZE.MEDIUM}
            name="nb_hours"
            max={MAX_NB_HOURS}
            min={MIN_NB_HOURS}
            step={0.5}
            FieldChildren={polyglot.toDuration(values.nb_hours, true)}
          />
        </CardBase>
      </StyledFormControl>
    </>
  );
};

export default NbHoursSelection;
