import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { spacings } from "@/assets/themes";
import { Link, List } from "@/components";
import { CardBase } from "@/components/Styles/Base";
import { DashboardContext } from "@/modules/contexts";
import { MEMBERSHIP_QUERY_KEY } from "@/utils";
import polyglot from "@/utils/polyglot";

const MembershipHelp = () => {
  const { public_pages } = useContext(DashboardContext);

  const data = useQueryClient().getQueryData([MEMBERSHIP_QUERY_KEY]);

  return (
    <div>
      <CardBase
        css={`
          padding-bottom: ${spacings.xs};
          position: sticky;
          overflow: hidden;
          top: calc(var(--nav-height) + ${spacings.m});
        `}
      >
        <List.Header
          withGutters
          RightComponent={() => (
            <Link href={public_pages.faq} target="_blank">
              {polyglot.t("common.see_more")}
            </Link>
          )}
        >
          {polyglot.t("common.need_help")}
        </List.Header>
        <List.Group>
          {data?.zendesk_articles.map((article, i) => (
            <List.Item
              key={`referral-zendesk-article-${i}`}
              chevron
              withGutters
              href={article.url}
              target="_blank"
            >
              {article.title}
            </List.Item>
          ))}
        </List.Group>
      </CardBase>
    </div>
  );
};

export default MembershipHelp;
