import { useState } from "react";
import { Block, Container, Icon, Link, Spinner } from "@/components";
import { useGetBookingData } from "@/modules/routes/jobber-booking-routes";
import { BUSINESS_MODEL, MEMBERSHIP_TYPE } from "@/utils";
import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import JobberSelectionTutorial from "../JobberSelectionTutorial";
import BookingHeader from "./BookingHeader";
import JobberBookingPass from "./JobberBookingPass";
import JobberBookingPrepaid from "./JobberBookingPrepaid";

const JobberBookingRoot = (props) => {
  const { data, isLoading } = useGetBookingData();
  const [tutorialModalIsOpen, setTutorialModalIsOpen] = useState(
    props.tutorial_is_open || false
  );
  const handleCloseTutorial = () => {
    setTutorialModalIsOpen(false);
  };

  const getJobber = () => {
    if (data?.offer?.jobber) {
      const newObj = { ...(data.offer.jobber || []) };
      newObj.url = null;
      return newObj;
    }
    return null;
  };

  const getBookingPage = () => {
    if (
      data?.business_model === BUSINESS_MODEL.MEMBERSHIP &&
      data?.membership.type === MEMBERSHIP_TYPE.MONTLHY_PASS
    ) {
      return <JobberBookingPass {...props} jobber={getJobber()} />;
    }

    return <JobberBookingPrepaid {...props} jobber={getJobber()} />;
  };
  return (
    <Block>
      <BookingHeader />
      {!isLoading ? (
        <>
          <Block marginY={spacings.ml}>
            <Container.Medium>
              <Block marginBottom={spacings.s}>
                <Link
                  href={data.job.url}
                  color={colors.muted}
                  css={`
                    display: inline-block;
                  `}
                >
                  <Icon.Medium name="arrow-left" />
                  &nbsp;
                  {polyglot.t("common.back")}
                </Link>
              </Block>
            </Container.Medium>
            {getBookingPage()}
          </Block>
        </>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={spacings.l}
        >
          <Spinner.Medium />
        </Block>
      )}
      <JobberSelectionTutorial
        isOpen={tutorialModalIsOpen}
        onClose={handleCloseTutorial}
      />
    </Block>
  );
};

export default JobberBookingRoot;
