import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import * as yup from "yup";
import { colors, spacings } from "../../assets/themes";
import { Block, Container, H1, H2, List } from "../../components";
import { CardBase } from "../../components/Styles/Base";
import { LIST } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";
import {
  useAddExtendedCover,
  useGetBookingData,
  useRemoveExtendedCover,
  useSubmitBooking,
} from "../../modules/routes/jobber-booking-routes";
import { useTokenizeQuery } from "../../modules/routes/payment-routes";
import {
  paymentCreditCardValidation,
  setPaymentInitialValues,
} from "../../utils";
import polyglot from "../../utils/polyglot";
import Methods from "../common/payment-methods/Methods";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import Aside from "./Aside";
import BookingSubmit from "./BookingSubmit";
import ExtendedCover from "./ExtendedCover";
import Faq from "./Faq";
import Payment from "./Payment";
import Summary from "./Summary";
import { getPricing } from "./utils";

const JobberBookingPrepaid = ({ jobber }) => {
  const addExtendedCover = useAddExtendedCover();
  const removeExtendedCover = useRemoveExtendedCover();

  const cesu = new URLSearchParams(window.location.search).get("cesu");

  const paymentMethodRef = useRef();
  // const { sendEvent } = useGoogleAnalytics();
  const { data } = useGetBookingData();
  const submit = useSubmitBooking();
  const formikRef = useRef();
  const submitBooking = useTokenizeQuery(submit.mutateAsync);
  const breakpoints = useBreakpoints();

  const handleSubmit = (values) => {
    // sendEvent(getBookingSubmitEvent({ kpi_kind }));
    submitBooking.mutate(values, {
      onError: (err) => {
        const mustResetCard = err.response.data?.data?.must_reset_card;
        if (mustResetCard) {
          paymentMethodRef.current?.reset();
        }
      },
    });
  };

  useEffect(() => {
    // sendEvent(getBookingOpenEvent({ kpi_kind }));
  }, []);

  return (
    <>
      <Block marginY={spacings.ml}>
        <Formik
          validationSchema={yup.lazy((values) => {
            if (getPricing(values, { ...data }).total > 0) {
              return paymentCreditCardValidation;
            }
            return yup.object().shape({});
          })}
          validateOnMount
          initialValues={{
            ...setPaymentInitialValues({
              ...data,
            }),
            cesu: cesu || false,
          }}
          innerRef={formikRef}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <Container.Medium>
                {breakpoints.get({
                  xs: (
                    <H2>
                      {polyglot.t("booking.booking_of_first_name", {
                        first_name: data.offer.jobber.first_name,
                      })}
                    </H2>
                  ),
                  md: (
                    <H1>
                      {polyglot.t("booking.booking_of_first_name", {
                        first_name: data.offer.jobber.first_name,
                      })}
                    </H1>
                  ),
                })}
                <Block
                  display="grid"
                  gridCols={{ xs: "1fr", md: "1fr 350px" }}
                  gap={spacings.l}
                >
                  <Block>
                    {breakpoints.get({
                      xs: (
                        <Block
                          marginTop={spacings.ml}
                          marginBottom={spacings.xs}
                        >
                          <JobberOfferItem
                            urlTargetBlank
                            jobber={jobber}
                            hideDetails
                            price={data.offer.price}
                            price_per_hour={data.offer.price_per_hour}
                            withGutters
                            shape={LIST.SHAPE.ROUND}
                            css={`
                              background-color: ${colors.background};
                            `}
                          />
                        </Block>
                      ),
                      md: null,
                    })}
                    {data.extended_cover_eligible && (
                      <Block marginTop={spacings.ml}>
                        <ExtendedCover
                          disabled={
                            submitBooking.isLoading || submitBooking.isSuccess
                          }
                          data={data}
                          add={addExtendedCover}
                          remove={removeExtendedCover}
                        />
                      </Block>
                    )}
                    {breakpoints.get({
                      xs: (
                        <Block>
                          <List.Header>
                            {polyglot.t("common.summary")}
                          </List.Header>
                          <CardBase flat>
                            <Block paddingX={spacings.m} paddingY={spacings.s}>
                              <Summary
                                isLoading={
                                  addExtendedCover.isLoading ||
                                  removeExtendedCover.isLoading
                                }
                              />
                            </Block>
                          </CardBase>
                        </Block>
                      ),
                      md: null,
                    })}
                    <Block marginBottom={spacings.ml}>
                      <List.Header
                        RightComponent={
                          breakpoints.get({
                            xs: false,
                            sm: true,
                          })
                            ? () => <Methods methods={data.payment_methods} />
                            : undefined
                        }
                      >
                        <div>{polyglot.t("payment_methods.title")}</div>
                      </List.Header>
                      <CardBase
                        flat
                        css={`
                          padding: ${breakpoints.get({
                            xs: `${spacings.xs} ${spacings.m}
                              ${spacings.m} ${spacings.m}`,
                            md: `${spacings.sm} ${spacings.ml}
                              ${spacings.ml} ${spacings.ml}`,
                          })};
                        `}
                      >
                        <Payment
                          mangopay_cards={data.mangopay_cards}
                          payment_methods={data.payment_methods}
                          cesu={data.cesu}
                          innerRef={paymentMethodRef}
                        />
                        <Block
                          position={isValid ? "sticky" : "static"}
                          bottom={spacings.m}
                        >
                          <BookingSubmit
                            handleSubmit={handleSubmit}
                            isLoading={
                              submitBooking.isLoading || submitBooking.isSuccess
                            }
                          />
                        </Block>
                      </CardBase>
                    </Block>
                    {data?.zendesk_articles?.length > 0 && (
                      <CardBase flat>
                        <Block
                          paddingBottom={spacings.sm}
                          paddingTop={{ xs: spacings.xs, md: spacings.sm }}
                        >
                          <Block paddingX={{ xs: spacings.m, md: spacings.ml }}>
                            <Faq data={data?.zendesk_articles} />
                          </Block>
                        </Block>
                      </CardBase>
                    )}
                  </Block>

                  {breakpoints.get({
                    md: (
                      <Block>
                        {!data.extended_cover_eligible ? (
                          <>
                            {/* Fix visual bloc alignement */}
                            <List.Header>&nbsp;</List.Header>
                          </>
                        ) : (
                          <Block height={spacings.ml} />
                        )}
                        <Aside
                          job={data.job}
                          jobber={jobber}
                          offer={data.offer}
                          show_phone_assistance={data.show_phone_assistance}
                          isLoading={
                            addExtendedCover.isLoading ||
                            removeExtendedCover.isLoading
                          }
                        />
                      </Block>
                    ),
                  })}
                </Block>
              </Container.Medium>
            </Form>
          )}
        </Formik>
      </Block>
    </>
  );
};

export default JobberBookingPrepaid;
