import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { borderWidth, colors, spacings } from "../../assets/themes";
import { Block, Body14, Body16, Divider, List } from "../../components";
import { ActiveWrapperBase } from "../../components/Styles/Base";
import { LIST } from "../../components/Styles/variants";
import { useGoogleAnalytics } from "../../modules/hooks";
import useMenu from "@/modules/hooks/useMenu";

const MenuItem = ({ item, strong }) => {
  const match = useRouteMatch(item.path);
  const { sendEvent } = useGoogleAnalytics();

  const hasChild = item.LeftComponent?.props?.children;
  const iconName =
    item.LeftComponent?.props?.name ||
    item.LeftComponent?.props?.children?.props?.name;

  const EnhancedChildren =
    item.LeftComponent &&
    React.cloneElement(item.LeftComponent, {
      name: `${iconName}${match ? "-solid" : ""}`,
      ...(hasChild && {
        children: {
          ...item.LeftComponent.props.children,
          props: {
            ...item.LeftComponent.props.children.props,
            name: `${iconName}${match ? "-solid" : ""}`,
          },
        },
      }),
    });

  return (
    <ActiveWrapperBase
      css={`
        margin-bottom: ${spacings.xs};
      `}
    >
      <List.Item
        as={item.path ? NavLink : "a"}
        to={item.path}
        href={item.href}
        target={item.href && "_blank"}
        onClick={item.event ? () => sendEvent(item.event) : null}
        divider={false}
        shape={LIST.SHAPE.ROUND}
        LeftComponent={EnhancedChildren ? () => EnhancedChildren : null}
        RightComponent={() => item.RightComponent}
      >
        <Body16 strong={strong} numberOfLines={1}>
          {item.renderTitle ? item.renderTitle() : item.title}
        </Body16>
        {item.subtitle && <Body14 color={colors.muted}>{item.subtitle}</Body14>}
      </List.Item>
    </ActiveWrapperBase>
  );
};
const Menu = () => {
  const { main, referral, alt } = useMenu();
  return (
    <Block
      borderRight={`solid ${borderWidth.m} ${colors.border}`}
      padding={spacings.s}
      width="100%"
      height="100%"
      background={colors.background}
    >
      {main.map((item) => (
        <MenuItem item={item} key={`menu-id-${item.path}`} strong />
      ))}

      {referral && (
        <>
          <Block marginTop={spacings.m} marginBottom={spacings.s}>
            <Divider.Cell />
          </Block>
          <MenuItem item={referral} strong />
        </>
      )}
      <Block marginBottom={spacings.m} marginTop={spacings.s}>
        <Divider.Cell />
      </Block>
      {alt.map((item) => (
        <MenuItem item={item} key={`menu-id-${item.path}`} />
      ))}
    </Block>
  );
};
const areEqual = (p, n) => JSON.stringify(p?.data) === JSON.stringify(n?.data);
export default React.memo(Menu, areEqual);
