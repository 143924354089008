import { BrowserRouter } from "react-router-dom";
import JobberBookingRoot from "@/pages/jobber-booking/JobberBookingRoot";
import BaseApp from "../modules/BaseApp";

const JobberBookingApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <BrowserRouter basename={rails.location.pathname}>
        <JobberBookingRoot {...props} />
      </BrowserRouter>
    </BaseApp>
  );

export default JobberBookingApp;
