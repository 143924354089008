import { useState } from "react";
import { CardBase } from "@/components/Styles/Base";
import JobbersEmptyStateImg from "../../assets/images/svg-icons/NoResults/jobbers.svg";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Button, List, NoResult } from "../../components";
import JobOfferItem from "../../components/JobOfferItem";
import { BUTTON } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import polyglot from "../../utils/polyglot";
import PublishJobModal from "../dashboard/job/PublishJobModal";
import CustomContainer from "./CustomContainer";
import JobOfferFilterButton from "./JobOfferFilterButton";
import JobVipBanner from "./JobVipBanner";
import JobWalletBanner from "./JobWalletBanner";
import OffersSkeleton from "./skeletons/OffersSkeleton";
import { getCanceledOffers, getSelectedOrPaidOffers } from "./utils";

const renderOffers = (offers) =>
  offers.map((offer, i, arr) => (
    <Block marginBottom={spacings.sm} key={`job-offer-${offer.jobber.id}`}>
      <CardBase flat>
        <JobOfferItem offer={offer} divider={i < arr.length - 1} />
      </CardBase>
    </Block>
  ));

const CustomNoResult = () => {
  const { data } = useJob();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClick = () => {
    setModalIsOpen(true);
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  if (!data.bookable) {
    return (
      <Block marginTop={spacings.xs} marginBottom={spacings.ml}>
        <Body16 color={colors.muted}>{polyglot.t("job.no_offers")}</Body16>
      </Block>
    );
  }

  if (data.private_jobber)
    return (
      <NoResult
        artwork={JobbersEmptyStateImg}
        title={polyglot.t("job.first_name_private_jobber_no_offer", {
          first_name: data.private_jobber?.first_name,
        })}
        subtitle={polyglot.t(
          "job.remaining_time_private_jobber_no_offer_description",
          { remaining_time: data.private_jobber?.private_job_time_remaining }
        )}
      >
        <Button.Medium kind={BUTTON.KIND.PRIMARY} onClick={handleClick}>
          {polyglot.t("job.publish_jobbers")}
        </Button.Medium>
        <PublishJobModal
          isOpen={modalIsOpen}
          onClose={handleClose}
          id={data.id}
        />
      </NoResult>
    );
  return (
    <NoResult
      artwork={JobbersEmptyStateImg}
      title={polyglot.t("job.no_offers")}
      subtitle={polyglot.t("job.city_no_offer", {
        city: data.city_name,
        time: data.avg_time_to_first_offer,
      })}
    />
  );
};

const JobOffers = () => {
  const { data, pendingOffers, isPoster } = useJob();

  return (
    <CustomContainer>
      {((data.bookable && data.is_vip) ||
        (data.bookable && data.wallet_amount > 0)) && (
        <Block
          display="flex"
          flexDirection="column"
          gap={spacings.s}
          marginTop={spacings.ml}
        >
          {data.wallet_amount > 0 && (
            <JobWalletBanner amount={data.wallet_amount} />
          )}
          {data.is_vip && <JobVipBanner />}
        </Block>
      )}
      {!isPoster && getSelectedOrPaidOffers(data.accepted_offers).length > 0 && (
        <Block marginTop={spacings.sm}>
          <List.Header>
            {polyglot.t("job.booked_jobbers_plural", {
              count: getSelectedOrPaidOffers(data.accepted_offers).length,
            })}
          </List.Header>
          {renderOffers(getSelectedOrPaidOffers(data.accepted_offers))}
        </Block>
      )}
      {getCanceledOffers(data.accepted_offers).length > 0 && (
        <Block marginTop={spacings.sm}>
          <List.Header>
            {polyglot.t("job.cancelled_jobbers", {
              count: getCanceledOffers(data.accepted_offers).length,
            })}
          </List.Header>
          {renderOffers(getCanceledOffers(data.accepted_offers))}
        </Block>
      )}
      <>
        <Block marginBottom={spacings.s}>
          <List.Header RightComponent={() => <JobOfferFilterButton />}>
            {polyglot.t("job.offers", { count: data.pending_offers.length })} (
            {data.pending_offers.length})
          </List.Header>
        </Block>
        {pendingOffers.isLoading ? (
          <OffersSkeleton />
        ) : (
          <>
            <Block>
              {data.pending_offers?.length === 0 ? (
                <CustomContainer>
                  <CustomNoResult />
                </CustomContainer>
              ) : (
                renderOffers(data.pending_offers)
              )}
            </Block>
          </>
        )}
      </>
    </CustomContainer>
  );
};
export default JobOffers;
