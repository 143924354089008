import { Body16, Icon, List } from "@/components";
import polyglot from "@/utils/polyglot";

const ADVANTAGES = [
  {
    icon: "calendar-check",
    title: polyglot.t("pass.advantage1"),
  },
  {
    icon: "wallet",
    title: polyglot.t("pass.advantage2"),
  },
  {
    icon: "clock",
    title: polyglot.t("pass.advantage3"),
  },
];

const MembershipAdvantages = (props) => (
  <div>
    {ADVANTAGES.map((advantage) => (
      <List.Item
        LeftComponent={() => <Icon.Large name={advantage.icon} />}
        divider={false}
        {...props}
      >
        <Body16 strong>{advantage.title}</Body16>
      </List.Item>
    ))}
  </div>
);

export default MembershipAdvantages;
