import { DashboardContext } from "@/modules/contexts";
import BaseApp from "../modules/BaseApp";
import HeaderMenu from "../pages/HeaderMenu";

const HeaderMenuApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <DashboardContext.Provider
        value={{
          public_pages: props.public_pages,
        }}
      >
        <HeaderMenu {...props} />
      </DashboardContext.Provider>
    </BaseApp>
  );

export default HeaderMenuApp;
