import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { DashboardContext } from "@/modules/contexts";
import useAccountMenu from "@/modules/hooks/useAccountMenu";
import useMenu from "@/modules/hooks/useMenu";
import polyglot from "@/utils/polyglot";
import DashboardWrapper from "../DashboardWrapper";
import JobberProfile from "../JobberProfile";
import { Content } from "./Layouts";
import DeleteAccount from "./account/DeleteAccount";
import TransactionDetailsModal from "./account/TransactionDetailsModal";
import {
  ACCOUNT_PATH,
  ACCOUNT_TAXES_PAY_PATH,
  ACCOUNT_WALLET_PATH,
  DELETE_ACCOUNT_PATH,
  JOBBER_PROFILE_PATH,
  NOTIFICATIONS_PATH,
} from "./config/routes";
import { Layouts, Notifications } from "./index";
import JobberTaxesModal from "./taxesCredit/JobberTaxesModal";

const DashboardRoutes = () => {
  const data = useSelector((state) => state.badges);
  const { public_pages } = useContext(DashboardContext);
  const { main, referral, alt } = useMenu();
  const location = useLocation();
  const queryClient = useQueryClient();
  const accountData = queryClient.getQueryData([ACCOUNT_PATH]);
  const user = accountData?.user;
  const background = location.state && location.state.background;
  const accountMenu = useAccountMenu();

  const renderAccountMenuRoutes = useCallback(
    () =>
      accountMenu.map((item) =>
        item.routes.flatMap((route) => {
          if (!route.path || route.hidden) return [];

          const routes = [
            <Route
              path={[route.path, `${route.path}/*`]}
              key={`account-route-${route.path}`}
              exact={route.exact}
            >
              <DashboardWrapper>
                <Layouts.Content title={route.title}>
                  <route.View
                    config={route}
                    key={`route-account-${route.path}`}
                  />
                </Layouts.Content>
              </DashboardWrapper>
            </Route>,
          ];

          if (route.routes) {
            const childRoutes = route.routes.map(
              (childRoute) =>
                childRoute.path && (
                  <Route
                    path={childRoute.path}
                    key={`account-route-${childRoute.path}`}
                    exact={childRoute.exact}
                  >
                    <DashboardWrapper>
                      <Layouts.Content title={childRoute.title}>
                        <childRoute.View
                          config={childRoute}
                          key={`route-account-${childRoute.path}`}
                        />
                      </Layouts.Content>
                    </DashboardWrapper>
                  </Route>
                )
            );
            routes.push(...childRoutes);
          }

          return routes;
        })
      ),
    [JSON.stringify(data), JSON.stringify(user)]
  );
  const renderMenuRoutes = useCallback(
    () =>
      [
        ...main,
        ...(referral ? [referral] : []),
        ...alt,
        {
          path: NOTIFICATIONS_PATH,
          title: polyglot.t("routes.notifications"),
          exact: true,
          View: Notifications,
          Layout: Content,
        },
        {
          path: DELETE_ACCOUNT_PATH,
          title: polyglot.t("routes.delete_account"),
          exact: true,
          View: DeleteAccount,
          Layout: Content,
        },
      ].map(
        (item) =>
          item.path && (
            <Route
              exact={item.exact}
              key={`route-id-${item.path}`}
              path={item.path}
            >
              <DashboardWrapper>
                <item.Layout title={item.title}>
                  <item.View config={item} public_pages={public_pages} />
                </item.Layout>
              </DashboardWrapper>
            </Route>
          )
      ),
    [JSON.stringify(data)]
  );
  return (
    <>
      <Switch location={background || location}>
        {renderMenuRoutes()}
        {renderAccountMenuRoutes()}
        <Route exact path={JOBBER_PROFILE_PATH}>
          <JobberProfile />
        </Route>
      </Switch>
      <Route
        path={[
          `${ACCOUNT_WALLET_PATH}/:id/details_offer`,
          `${ACCOUNT_WALLET_PATH}/:id/details_direct_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_subscription_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_membership_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_instant_payment`,
        ]}
      >
        <TransactionDetailsModal />
      </Route>

      <Route path={ACCOUNT_TAXES_PAY_PATH}>
        <JobberTaxesModal />
      </Route>
    </>
  );
};

export default DashboardRoutes;
