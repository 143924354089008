import styled from "styled-components";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Divider, H5, List } from "../../components";
import { CardBase } from "../../components/Styles/Base";
import UserPreview from "../../pages-admin/UserPreview/UserPreview";
import { JOB } from "../../pages-admin/UserPreview/config";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import AssistanceBlock from "./AssistanceBlock";
import Summary from "./Summary";
import TrustBox from "./TrustBox";

const EnhancedCard = styled(CardBase)`
  overflow: hidden;
`;

const Aside = ({
  job,
  jobber,
  show_phone_assistance,
  offer,
  isLoading,
  hideSummary,
}) => (
  <Block>
    <EnhancedCard flat>
      <Block paddingBottom={spacings.m}>
        <UserPreview id={job.id} kind={JOB}>
          <Block
            width="100%"
            maxHeight={180}
            minHeight={130}
            backgroundImage={`url(${job.cover})`}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="bottom right"
            display="block"
            backgroundColor={colors.gray100}
            paddingBottom="40%"
            marginBottom={spacings.m}
          />
          <Block paddingX={spacings.ml}>
            <a
              href={job.url}
              target="_blank"
              css={`
                display: block;
                text-decoration: none !important;
                color: ${colors.body} !important;
              `}
              rel="noreferrer"
            >
              <Block display="flex" justifyContent="space-between">
                <H5 numberOfLines={1}>{job.title}</H5>
              </Block>
              <Body16 color={colors.muted}>{job.datetime}</Body16>
            </a>
          </Block>
        </UserPreview>
      </Block>
      <Divider.Group />
      <Block paddingX={spacings.ml}>
        <JobberOfferItem
          urlTargetBlank
          jobber={jobber}
          hideDetails
          price={offer?.price}
          price_per_hour={offer?.price_per_hour}
        />
      </Block>
      {!hideSummary && (
        <>
          <Divider.Group />
          <Block paddingX={spacings.ml}>
            <Summary isLoading={isLoading} />
          </Block>
        </>
      )}
    </EnhancedCard>
    {show_phone_assistance && (
      <Block marginTop={spacings.m}>
        <EnhancedCard flat>
          <Block margin={spacings.m}>
            <AssistanceBlock />
          </Block>
        </EnhancedCard>
      </Block>
    )}
    <Block marginY={spacings.m}>
      <TrustBox />
    </Block>
  </Block>
);

export default Aside;
