import { spacings } from "@/assets/themes";
import { Block } from "@/components";
import { useGetMemberships } from "@/modules/routes/dashboard-routes";
import MembershipSkeleton from "../../skeletons/MembershipSkeleton";
import MembershipHelp from "./MembershipHelp";
import useMembershipRedirect from "./useMembershipRedirect";

const MembershipLayout = ({ children }) => {
  const { isLoading } = useGetMemberships();
  const { renderContent } = useMembershipRedirect();

  return (
    <div>
      {isLoading ? (
        <MembershipSkeleton />
      ) : (
        <Block
          display="grid"
          gridCols={{ xs: 1, lg: "1fr 350px" }}
          gap={spacings.l}
        >
          <Block>{renderContent(children)}</Block>
          <MembershipHelp />
        </Block>
      )}
    </div>
  );
};

export default MembershipLayout;
