import { useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { spacings } from "@/assets/themes";
import { POPOVER } from "@/components/Styles/variants";
import { formatCardNumber } from "@/utils";
import { Block, Body16, Button, Divider, Popover } from "../../../components";
import { useGoogleAnalytics } from "../../../modules/hooks";
import { getApplePayButtonAvailableEvent } from "../../../utils/analytics-events";
import polyglot from "../../../utils/polyglot";
import MethodItem from "./MethodItem";
import MethodsOptions from "./MethodsOptions";
import NewCreditCard from "./NewCreditCard";
import { APPLE_PAY } from "./constants";
import { browserInfo, getPaymentMethodConfig } from "./utils";

const PaymentMethodsUpdated = React.forwardRef(
  (
    {
      methods: _methods = [],
      onChange,
      disabled,
      withGutters,
      mangopay_cards,
      divider,
    },
    ref
  ) => {
    const creditCardRef = useRef();
    const { sendEvent } = useGoogleAnalytics();
    const isApplePayAvailable = !!window.ApplePaySession;
    const { validateForm, setValues, values, setFieldValue } =
      useFormikContext();
    const [lastCreditCardSelected, setLastCreditCardSelected] = useState(null);
    const [selectCardIsOpen, setSelectCardIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);

    let methods = _methods;

    methods = _methods
      ?.filter((m) => (isApplePayAvailable ? true : m !== APPLE_PAY))
      .map((m) => m.toLowerCase());

    useEffect(() => {
      setFieldValue("browserInfo", browserInfo);
    }, []);

    useEffect(() => {
      // set credit card selected
      if (values?.payment_method && values?.mangopay_card_id) {
        setLastCreditCardSelected(
          mangopay_cards?.find((card) => card.id === values?.mangopay_card_id)
        );
      } else if (!values?.payment_method) {
        setLastCreditCardSelected(null);
      }
    }, [values?.mangopay_card_id, values?.payment_method]);

    useEffect(() => {
      // set initial credit card
      if (
        values?.payment_method &&
        getPaymentMethodConfig(values?.payment_method).external &&
        mangopay_cards?.findIndex((card) => card.current) !== -1
      ) {
        setLastCreditCardSelected(mangopay_cards?.find((card) => card.current));
      }
    }, [mangopay_cards]);

    useLayoutEffect(() => {
      if (
        !values.mangopay_card_id && values.payment_method
          ? !getPaymentMethodConfig(values.payment_method)?.external &&
            mangopay_cards?.length > 0
          : false
      ) {
        setFieldValue(
          "mangopay_card_id",
          mangopay_cards?.find((card) => card.current)?.id
        );
      }
    }, [mangopay_cards]);

    useEffect(() => {
      if (methods.includes(APPLE_PAY)) {
        sendEvent(getApplePayButtonAvailableEvent());
      }
    }, [methods]);

    useEffect(() => {
      if (ref && creditCardRef.current) {
        Object.assign(ref.current, creditCardRef.current);
      }
    }, [creditCardRef.current, ref]);

    useImperativeHandle(ref, () => ({}), []);

    const handleChange = (params) => {
      const arr = Object.fromEntries(
        [
          "card_number",
          "card_type",
          "mangopay_card_id",
          "current",
          "token",
          "payment_method",
        ]
          .filter((key) => key in params)
          .map((key) => [key, params[key]])
      );

      setValues((s) => ({ ...s, ...arr }), false);
      setShowForm(
        !getPaymentMethodConfig(arr.payment_method)?.external &&
          !arr?.mangopay_card_id
      );
      setTimeout(() => {
        validateForm();
      }, 0);
      if (onChange) onChange(arr);
    };

    return (
      <>
        <Popover.Enhancer
          sameWidth
          isOpen={selectCardIsOpen}
          trigger={POPOVER.TRIGGER_TYPE.CLICK}
          position={POPOVER.POSITIONS.BOTTOM}
          onClose={() => setSelectCardIsOpen(false)}
          content={({ close }) => (
            <Popover.Elem.Menu>
              <MethodsOptions
                withGutters
                selectedCard={lastCreditCardSelected}
                methods={methods}
                disabled={disabled}
                cards={mangopay_cards}
                onChange={(v) => {
                  handleChange(v);
                  close();
                }}
              />
            </Popover.Elem.Menu>
          )}
        >
          <div>
            <MethodItem
              withGutters={withGutters}
              method={lastCreditCardSelected?.kind}
              divider={divider ?? !showForm}
              disabled={disabled}
              onClick={() => {
                handleChange({
                  mangopay_card_id: lastCreditCardSelected?.id,
                  payment_method: lastCreditCardSelected?.kind,
                });
              }}
              checked={showForm || values?.mangopay_card_id}
              RightComponent={() => (
                <>
                  {mangopay_cards?.length > 0 && (
                    <Block display="flex" alignItems="center">
                      <Button.Link
                        css={`
                          padding-left: 0;
                          padding-right: 0;
                        `}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectCardIsOpen(true);
                        }}
                      >
                        {polyglot.t("common.edit")}
                      </Button.Link>
                    </Block>
                  )}
                </>
              )}
            >
              <Body16 strong={lastCreditCardSelected}>
                {formatCardNumber(lastCreditCardSelected?.alias) ||
                  polyglot.t("payment_method.payment_card")}
              </Body16>
            </MethodItem>
          </div>
        </Popover.Enhancer>

        <AnimatePresence>
          {showForm && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              style={{ overflow: "hidden" }}
              transition={{ duration: 0.2 }}
            >
              <NewCreditCard
                methods={methods}
                mangopay_cards={mangopay_cards}
                withGutters={withGutters}
                onChange={handleChange}
                disabled={disabled}
                ref={creditCardRef}
              />
              <Block paddingTop={spacings.ml} paddingBottom={spacings.s}>
                <Divider.Cell />
              </Block>
            </motion.div>
          )}
        </AnimatePresence>

        <MethodsOptions
          withGutters={withGutters}
          methods={methods.filter(
            (method) => getPaymentMethodConfig(method)?.external
          )}
          isExternal
          disabled={disabled}
          cards={mangopay_cards}
          onChange={handleChange}
        />
      </>
    );
  }
);

export default PaymentMethodsUpdated;
