import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  sizes,
  spacings,
} from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body16,
  Breadcrumb,
  Button,
  ConditionnalWrapper,
  Icon,
  InfiniteScrollEnhancer,
  Link,
  List,
  NoResult,
  Selectable,
  Shape,
  StatusEnhancer,
  Tag,
} from "../../../components";
import {
  BUTTON,
  SELECTABLE,
  SHAPE,
  TAG,
} from "../../../components/Styles/variants";
import { useGetAccountBillings } from "../../../modules/routes/dashboard-routes";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import { JOBBER } from "../../../pages-admin/UserPreview/config";
import { formatDate } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import PageTitle from "../PageTitle";
import {
  ACCOUNT_BILLING_PATH,
  ACCOUNT_PATH,
  ACCOUNT_WALLET_PATH,
} from "../config/routes";
import BillingsSkeleton from "../skeletons/BillingsSkeleton";

const CesuBillingItem = ({ name, url }) => (
  <List.Item
    href={url}
    target="_blank"
    LeftComponent={() => <Icon.Medium name="download" color={colors.primary} />}
  >
    <Body16 strong color={colors.primary}>
      {name}
    </Body16>
  </List.Item>
);

const TaxCertificatesHeader = ({ date, url }) => (
  <List.Header
    RightComponent={() =>
      url && (
        <Link href={url} target="_blank">
          <Icon.Medium name="download" /> {polyglot.t("common.tax_certificate")}{" "}
          {formatDate(date, "YYYY")}
        </Link>
      )
    }
  >
    {polyglot.t("common.year")} {formatDate(date, "YYYY")}
  </List.Header>
);

const RenderFilter = ({ data }) => {
  const { location } = useHistory();
  if (location.search === "?filter=tax_certificates") {
    return (
      <>
        {data.tax_certificates.map((tax, index) => (
          <TaxCertificatesHeader
            key={`tax-header-${index}`}
            date={tax.name}
            url={tax.url}
          />
        ))}
      </>
    );
  }
  if (location.search === "?filter=cesu_fees") {
    return (
      <>
        {data.cesu_fees.map((cesu_billing, index, arr) => (
          <Block key={`cesu-billing-block-${index}`}>
            {formatDate(cesu_billing.date, "YYYY") !==
              formatDate(arr[index - 1]?.date, "YYYY") && (
              <List.Header>{formatDate(cesu_billing.date, "YYYY")}</List.Header>
            )}
            <CesuBillingItem
              key={`billing-item-${index}`}
              name={cesu_billing.name}
              url={cesu_billing.url}
            />
          </Block>
        ))}
      </>
    );
  }
  return (
    <>
      {data.transactions.map((transaction, index, arr) => (
        <Block key={`tax-block-${index}`}>
          {formatDate(transaction.date, "YYYY") !==
            formatDate(arr[index - 1]?.date, "YYYY") && (
            <TaxCertificatesHeader
              date={formatDate(transaction.date, "YYYY")}
              url={
                data.tax_certificates.find(
                  (tax) => tax.name === formatDate(transaction.date, "YYYY")
                )?.url
              }
            />
          )}
          <BillingItem key={`billing-item-${index}`} {...transaction} />
        </Block>
      ))}
      {data.transactions?.length === 0 && (
        <NoResult
          title={polyglot.t("billings.no_document")}
          subtitle={polyglot.t("billings.no_document_description")}
        />
      )}
    </>
  );
};

const BillingItem = ({
  title,
  user,
  documents,
  date,
  url,
  type,
  id,
  state,
  image,
}) => {
  const location = useLocation();
  return (
    <Block
      paddingY={spacings.s}
      boxShadow={shadows.xs}
      marginBottom={spacings.l}
      borderRadius={radius.m}
      border={`solid ${borderWidth.s} ${colors.border}`}
    >
      <ConditionnalWrapper
        condition={user?.id}
        wrapper={(children) => (
          <UserPreview id={user?.id} kind={JOBBER}>
            {children}
          </UserPreview>
        )}
      >
        <List.Item
          divider={false}
          withGutters
          RightComponent={() => (
            <List.Elem.Label>{formatDate(date, "DD/MM/YYYY")}</List.Elem.Label>
          )}
          LeftComponent={() => {
            if (user) {
              return (
                <div as={user.url && Link} href={user.url} css="">
                  <Avatar
                    size={sizes.size48}
                    src={user.avatar}
                    name={user.first_name}
                  />
                </div>
              );
            }
            return (
              <img
                src={image}
                alt={title}
                css={`
                  width: ${sizes.size48};
                  height: ${sizes.size48};
                  border-radius: ${radius.circle};
                `}
              />
            );
          }}
        >
          <Block display="flex" alignItems="center">
            <Link href={url} strong color={colors.body} numberOfLines={1}>
              {title}
            </Link>
            {state === "canceled" && (
              <Block marginLeft={spacings.s}>
                <Tag.Small
                  LeftComponent={() => <Icon.Small name="user-times-solid" />}
                  kind={TAG.KIND.DANGER}
                >
                  {polyglot.t("common.canceled")}
                </Tag.Small>
              </Block>
            )}
          </Block>
          {user && (
            <div
              as={user.url && Link}
              href={user.url}
              css={`
                &,
                * {
                  font-weight: inherit !important;
                  color: ${colors.muted}!important;
                }
              `}
            >
              <StatusEnhancer isPro={user.is_pro}>
                <Body16 numberOfLines={1}>{user.first_name}</Body16>
              </StatusEnhancer>
            </div>
          )}
        </List.Item>
      </ConditionnalWrapper>
      {documents &&
        documents.map((document, index) => (
          <List.Item
            key={`billing-document-${index}`}
            divider={index < document.length - 1}
            href={document.url}
            withGutters
            LeftComponent={() => (
              <Icon.Large name="download" color={colors.primary} />
            )}
          >
            <List.Elem.Title strong color={colors.primary}>
              {document.name}
            </List.Elem.Title>
          </List.Item>
        ))}
      <Block marginY={spacings.s} paddingX={spacings.m}>
        <Button.Medium
          kind={BUTTON.KIND.SECONDARY}
          as={Link}
          to={{
            pathname: `${ACCOUNT_WALLET_PATH}/${id}/details_${type}`,
            state: { background: location },
          }}
          block
        >
          {polyglot.t("job.check_transaction_details")}
        </Button.Medium>
      </Block>
    </Block>
  );
};

const Billings = ({ config }) => {
  const history = useHistory();
  const { search } = useLocation();
  const getBillings = useGetAccountBillings();

  const taxCertificatesIsActive = search === "?filter=tax_certificates";
  const cesuIsActive = search === "?filter=cesu_fees";
  const allIsActive = !taxCertificatesIsActive && !cesuIsActive;

  return (
    <Block maxWidth={550} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <PageTitle>{config.title}</PageTitle>
      <InfiniteScrollEnhancer query={getBillings} unionBy="transactions">
        {({ isLoading, data }) =>
          !isLoading ? (
            <>
              <Block
                display="flex"
                flexWrap="nowrap"
                overflowX="auto"
                marginTop={{ xs: spacings.s, md: spacings.m }}
                marginX={`calc(${spacings.m} * -1)`}
                paddingX={spacings.m}
                css={`
                  &::-webkit-scrollbar {
                    display: none;
                  }
                  -ms-overflow-style: none;
                  scrollbar-width: none;
                `}
                alignItems="center"
              >
                <Selectable.Small
                  shape={SELECTABLE.SHAPE.CIRCLE}
                  onChange={() => history.replace(ACCOUNT_BILLING_PATH)}
                  checked={allIsActive}
                  css={`
                    margin-right: ${spacings.s};
                  `}
                >
                  {polyglot.t("common.all")}
                </Selectable.Small>
                {polyglot.country === "fr" && (
                  <>
                    <Selectable.Small
                      shape={SELECTABLE.SHAPE.CIRCLE}
                      checked={taxCertificatesIsActive}
                      disabled={
                        !data.tax_certificates ||
                        data.tax_certificates?.length === 0
                      }
                      onChange={() =>
                        history.replace({
                          pathname: ACCOUNT_BILLING_PATH,
                          search: "?filter=tax_certificates",
                        })
                      }
                      css={`
                        margin-right: ${spacings.s};
                      `}
                    >
                      {polyglot.t("common.tax_certificates")}
                    </Selectable.Small>
                    <Selectable.Small
                      shape={SELECTABLE.SHAPE.CIRCLE}
                      checked={cesuIsActive}
                      disabled={!data.cesu_fees || data.cesu_fees?.length === 0}
                      onChange={() =>
                        history.replace({
                          pathname: ACCOUNT_BILLING_PATH,
                          search: "?filter=cesu_fees",
                        })
                      }
                    >
                      {polyglot.t("common.cesu")}
                    </Selectable.Small>
                  </>
                )}
              </Block>
              <RenderFilter data={data} />
            </>
          ) : (
            <BillingsSkeleton />
          )
        }
      </InfiniteScrollEnhancer>
    </Block>
  );
};

export default Billings;
