import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { spacings } from "@/assets/themes";
import { Block, Body16, Button, List, Radio, Textarea } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { usePutMembershipCancel } from "@/modules/routes/dashboard-routes";
import PageTitle from "@/pages/dashboard/PageTitle";
import { MEMBERSHIP_CANCEL_CONFIRMATION } from "@/utils";
import polyglot from "@/utils/polyglot";

const validationSchema = Yup.object().shape({
  confirmation: Yup.string()
    .required()
    .oneOf([...MEMBERSHIP_CANCEL_CONFIRMATION]),
  custom_reason: Yup.string().when("confirmation", {
    is: "other",
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
});

const Confirm = () => {
  const { mutate, isLoading } = usePutMembershipCancel();

  const formik = useFormik({
    initialValues: {
      confirmation: "",
      custom_reason: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutate({
        reason: values.confirmation,
        custom_reason: values.custom_reason,
      });
    },
  });

  useEffect(() => {
    formik.setFieldValue("custom_reason", "");
  }, [formik.values.confirmation]);

  return (
    <div>
      <PageTitle>{polyglot.t("membership.cancel_confirmation")}</PageTitle>
      <Block marginTop={spacings.xs}>
        <Body16>{polyglot.t("membership.cancel_confirmation_subtitle")}</Body16>
      </Block>
      <Block spaceY={spacings.s} marginTop={spacings.m}>
        <Block spaceY={spacings.s}>
          <Block>
            <List.Group>
              {MEMBERSHIP_CANCEL_CONFIRMATION.map((item) => (
                <List.Item
                  key={item}
                  onClick={() => {
                    formik.setFieldValue("confirmation", item);
                  }}
                  RightComponent={() => (
                    <Radio checked={formik.values.confirmation === item} />
                  )}
                >
                  <Body16 strong>
                    {polyglot.t(`membership.cancellation_reason_${item}`)}
                  </Body16>
                </List.Item>
              ))}
            </List.Group>
          </Block>
          {formik.values.confirmation === "other" && (
            <Textarea
              value={formik.values.custom_reason}
              placeholder={polyglot.t("membership.cancellation_other_reason")}
              onChange={(e) => {
                formik.setFieldValue("custom_reason", e.target.value);
              }}
              error={
                formik.touched.custom_reason && formik.errors.custom_reason
              }
            />
          )}
        </Block>
        <Block display="flex" gap={spacings.s} flexDirection="column">
          <Button.Large
            onClick={formik.handleSubmit}
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            disabled={!formik.isValid}
            isLoading={isLoading}
          >
            {polyglot.t("membership.cancellation_confirm")}
          </Button.Large>
        </Block>
      </Block>
    </div>
  );
};

export default Confirm;
