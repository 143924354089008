import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { spacings } from "@/assets/themes";
import { Block, Button } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import PageTitle from "@/pages/dashboard/PageTitle";
import MembershipAdvantages from "../MembershipAdvantages";
import { ACCOUNT_MEMBERSHIP_MANAGE_PATH } from "@/pages/dashboard/config/routes";
import polyglot from "@/utils/polyglot";

const PreConfirm = ({ goNext }) => {
  const history = useHistory();

  return (
    <div>
      <PageTitle>
        {polyglot.t("membership.cancel_confirmation_title")}
      </PageTitle>
      <Block marginY={spacings.m}>
        <MembershipAdvantages />
      </Block>
      <Block display="flex" gap={spacings.s} flexDirection="column">
        <Button.Large
          onClick={() => history.push(ACCOUNT_MEMBERSHIP_MANAGE_PATH)}
        >
          {polyglot.t("membership.keep_subscription")}
        </Button.Large>
        <Button.Large kind={BUTTON.KIND.SECONDARY} onClick={goNext}>
          {polyglot.t("membership.cancel_subscription")}
        </Button.Large>
      </Block>
    </div>
  );
};

export default PreConfirm;
