import moment from "moment";
import * as yup from "yup";
import capitalize from "./capitalize";
import polyglot from "./polyglot";

const addressValidation = {
  place_id: yup
    .string()
    .nullable()
    .when(["address_id", "address_name", "city_id"], {
      is: (address_id, address_name, city_id) =>
        !address_id && !address_name && !city_id,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
  address_id: yup
    .string()
    .nullable()
    .when(["place_id", "address_name", "city_id"], {
      is: (place_id, address_name, city_id) =>
        !place_id && !address_name && !city_id,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
  address_name: yup
    .string()
    .nullable()
    .when("city_id", {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
  city_id: yup
    .string()
    .nullable()
    .when("address_name", {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
};

const destinationAddressValidation = {
  destination_place_id: yup
    .string()
    .nullable()
    .when(
      [
        "destination_address_id",
        "destination_address_name",
        "destination_city_id",
        "destination_same",
      ],
      {
        is: (
          destination_address_id,
          destination_address_name,
          destination_city_id,
          destination_same
        ) =>
          !destination_address_id &&
          !destination_address_name &&
          !destination_city_id &&
          !destination_same,
        then: yup
          .string()
          .nullable()
          .required(polyglot.t("common.errors.field_required")),
      }
    ),
  destination_address_id: yup
    .string()
    .nullable()
    .when(
      [
        "destination_place_id",
        "destination_address_name",
        "destination_city_id",
        "destination_same",
      ],
      {
        is: (
          destination_place_id,
          destination_address_name,
          destination_city_id,
          destination_same
        ) =>
          !destination_place_id &&
          !destination_address_name &&
          !destination_city_id &&
          !destination_same,
        then: yup
          .string()
          .nullable()
          .required(polyglot.t("common.errors.field_required")),
      }
    ),
  destination_address_name: yup
    .string()
    .nullable()
    .when(["destination_city_id", "destination_same"], {
      is: (destination_city_id, destination_same) =>
        destination_city_id && !destination_same,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
  destination_city_id: yup
    .string()
    .nullable()
    .when(["destination_address_name", "destination_same"], {
      is: (destination_address_name, destination_same) =>
        destination_address_name && !destination_same,
      then: yup
        .string()
        .nullable()
        .required(polyglot.t("common.errors.field_required")),
    }),
};

const nameIsValid = (value) =>
  value.trim() !== "" &&
  !value.includes("@") &&
  !value.toLowerCase().includes("gmail") &&
  !value.toLowerCase().includes("outlook") &&
  !value.toLowerCase().includes("hotmail") &&
  !value.includes(".") &&
  !value.includes("_") &&
  !/\d/.test(value);

const firstNameValidation = {
  first_name: yup
    .string()
    .test(
      "first_name",
      polyglot.t("common.errors.not_accepted_words"),
      (value) => {
        if (!value) {
          return true;
        }
        return nameIsValid(value);
      }
    )
    .required(""),
};

const lastNameValidation = {
  last_name: yup
    .string()
    .test(
      "last_name",
      polyglot.t("common.errors.not_accepted_words"),
      (value) => {
        if (!value) {
          return true;
        }
        return nameIsValid(value);
      }
    )
    .required(""),
};

const paymentValidation = {
  payment_method: yup.string().when("default_payment_method", {
    is: (v) => v === null || v === false || v === undefined,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  iban: yup.string().notRequired(),
  token: yup.string().notRequired(),
  voucher_code: yup.string().notRequired(),
};

const paymentCreditCardValidation = yup.object().shape(
  {
    cardNumber: yup.string().when("payment_method", {
      is: false,
      then: yup.string().required(),
    }),
    cardExpirationDate: yup.string().when("payment_method", {
      is: false,
      then: yup.string().required(),
    }),
    payment_method: yup
      .string()
      .nullable()
      .when(["cardNumber", "cardExpirationDate"], {
        is: (cardNumber, cardExpirationDate) =>
          !!cardNumber && !!cardExpirationDate,
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().required(),
      }),
    iban: yup.string().notRequired(),
    token: yup.string().notRequired(),
    voucher_code: yup.string().notRequired(),
  },
  [
    ["cardNumber", "payment_method"],
    ["cardExpirationDate", "payment_method"],
  ]
);

const creditCardValidation = {
  cardNumber: yup.string().required(),
  cardExpirationDate: yup.string().required(),
  cardCvx: yup.string().notRequired(),
};

const IBANValidation = {
  iban: yup.string().required(),
};

const setAddressInitialValues = (values = {}) => ({
  place_id: values.place_id || "",
  address_name: values.address_name || "",
  address: values.address || "",
  address_id: values.address_id || "",
  city_id: values.city_id || "",
});

const setDestinationAddressInitialValues = (values = {}) => ({
  destination_place_id: values.destination_place_id || "",
  destination_address_name: values.destination_address_name || "",
  destination_address: values.destination_address || "",
  destination_address_id: values.destination_address_id || "",
  destination_city_id: values.destination_city_id || "",
});

const setPaymentInitialValues = (values = {}) => ({
  payment_method: values.payment_method || null,
  iban: values.iban || "",
  token: values.token || "",
  voucher_code: values.voucher_code || "",
  card_number: values.card_number || "",
  card_type: values.card_type || "",
  mangopay_card_id: values.mangopay_card_id || null,
  default_payment_method: values.default_payment_method || "",
  current: false,
  cardNumber: "",
  cardExpirationDate: "",
  cardCvx: "",
});

const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const emailRegex =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const dateOfBirthRegex =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

const setCheckboxFieldValue = (name, value, { setFieldValue, values }) => {
  const currValue = values?.[name] || [];
  const checked = currValue?.includes(value) || false;
  if (!checked) {
    return setFieldValue && setFieldValue(name, [...currValue, value]);
  }
  return (
    setFieldValue &&
    setFieldValue(
      name,
      currValue?.filter((v) => v !== value)
    )
  );
};

export const getYearsOptions = ({ isChild = false }) => {
  const max = isChild ? 17 : 100;
  const min = isChild
    ? new Date().getFullYear() + 1
    : new Date().getFullYear() - 17;

  return new Array(max).fill("").map((_, i) => ({
    value: `${min - 1 - i}`,
    label: `${min - 1 - i}`,
  }));
};

export const getDaysOptions = () =>
  new Array(31).fill("").map((_, i) => {
    const c = i + 1;
    const formated = c < 10 ? `0${c}` : `${c}`;
    return { value: formated, label: formated };
  });

export const getMonthOptions = () =>
  new Array(12).fill("").map((_, i) => {
    const c = i + 1;
    return {
      value: c < 10 ? `0${c}` : `${c}`,
      label: capitalize(moment.months(i)),
    };
  });

export {
  addressValidation,
  destinationAddressValidation,
  setAddressInitialValues,
  setDestinationAddressInitialValues,
  setPaymentInitialValues,
  paymentValidation,
  creditCardValidation,
  paymentCreditCardValidation,
  IBANValidation,
  phoneRegex,
  emailRegex,
  dateOfBirthRegex,
  setCheckboxFieldValue,
  firstNameValidation,
  lastNameValidation,
};
