import { useRef } from "react";
import styled, { css } from "styled-components";
import { colors, sizes } from "../assets/themes";
import Button from "./Button";
import Icon from "./Icon";
import Popover from "./popover/Popover";
import { BUTTON, INFO, POPOVER } from "./Styles/variants";

const getWidth = ({ size }) => {
  if (size === INFO.SIZE.SMALL) return sizes.size24;
  if (size === INFO.SIZE.LARGE) return sizes.size48;
  return sizes.size36;
};

const StyledWrapper = styled.div`
  ${({ useDefaultStyle, size }) =>
    useDefaultStyle &&
    css`
      height: ${sizes.size24};
      width: ${getWidth({ size })};
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  * {
    cursor: help !important;
  }
`;

const Info = ({
  children,
  render,
  PopoverContainer = Popover.Elem.Tooltip,
  icon = "info-circle-solid",
  size = INFO.SIZE.MEDIUM,
  noPortal,
  className,
  tabIndex,
}) => {
  const wrapperRef = useRef();

  return (
    <StyledWrapper
      useDefaultStyle={!render}
      className={className}
      size={size}
      ref={wrapperRef}
    >
      <Popover.Enhancer
        trigger={POPOVER.TRIGGER_TYPE.CLICK}
        content={() => <PopoverContainer>{children}</PopoverContainer>}
        mountNode={noPortal ? wrapperRef.current : null}
      >
        {render ? (
          render()
        ) : (
          <Button.Small
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            css="background-color: transparent!important;"
            tabIndex={tabIndex}
          >
            {size === INFO.SIZE.SMALL && (
              <Icon.Small name={icon} color={colors.muted} />
            )}
            {size === INFO.SIZE.MEDIUM && (
              <Icon.Medium name={icon} color={colors.muted} />
            )}
            {size === INFO.SIZE.LARGE && (
              <Icon.Large name={icon} color={colors.muted} />
            )}
          </Button.Small>
        )}
      </Popover.Enhancer>
    </StyledWrapper>
  );
};

export default Info;
