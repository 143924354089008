import { useContext } from "react";
import VipBadge from "@/assets/images/svg-icons/Badges/Vip.svg";
import { colors, sizes, spacings } from "@/assets/themes";
import { Badge, Block, Body14, Body16, Icon, Tag } from "@/components";
import Progress from "@/components/progress";
import { BADGE, PROGRESS, TAG } from "@/components/Styles/variants";
import Billings from "@/pages/dashboard/account/Billings";
import BlockedJobbers from "@/pages/dashboard/account/BlockedJobbers";
import Business from "@/pages/dashboard/account/Business";
import Cesu from "@/pages/dashboard/account/Cesu";
import EditAccount from "@/pages/dashboard/account/EditAccount";
import ManageNotifications from "@/pages/dashboard/account/ManageNotifications";
import ManagePaymentMethods from "@/pages/dashboard/account/ManagePaymentMethods";
import CancelMembership from "@/pages/dashboard/account/membership/cancel/CancelMembership";
import ManageMembership from "@/pages/dashboard/account/membership/manage/ManageMembership";
import PassLanding from "@/pages/dashboard/account/membership/PassLanding";
import Wallet from "@/pages/dashboard/account/Wallet";
import {
  ACCOUNT_BILLING_PATH,
  ACCOUNT_BLOCKED_JOBBERS_PATH,
  ACCOUNT_CESU_PATH,
  ACCOUNT_COMPANY_PATH,
  ACCOUNT_INFORMATIONS_PATH,
  ACCOUNT_MEMBERSHIP_CANCEL_PATH,
  ACCOUNT_MEMBERSHIP_MANAGE_PATH,
  ACCOUNT_MEMBERSHIP_PATH,
  ACCOUNT_NOTIFICATIONS_PATH,
  ACCOUNT_PAYMENT_METHODS_PATH,
  ACCOUNT_TAXES_PATH,
  ACCOUNT_WALLET_PATH,
  REFERRAL_PATH,
  TICKETS_PATH,
} from "@/pages/dashboard/config/routes";
import Referral from "@/pages/dashboard/Referral";
import TaxesCredit from "@/pages/dashboard/TaxesCredit";
import Tickets from "@/pages/dashboard/Tickets";
import { formatDate, MEMBERSHIP_STATES } from "@/utils";
import { getReferralMenuClickEvent } from "@/utils/analytics-events";
import polyglot from "@/utils/polyglot";
import { DashboardContext } from "../contexts";
import { useGetAccount } from "../routes/dashboard-routes";

const useAccountMenu = () => {
  const { data, isLoading } = useGetAccount();
  const { public_pages } = useContext(DashboardContext);
  if (isLoading) {
    return [];
  }
  return [
    {
      title: polyglot.t("account.manage_my_account"),
      routes: [
        {
          path: ACCOUNT_INFORMATIONS_PATH,
          title: polyglot.t("routes.personal_informations"),
          subtitle: polyglot.t("routes.personal_informations_description"),
          LeftComponent: () => (
            <Icon.XLarge name="user" color={colors.primary} />
          ),
          View: EditAccount,
        },
        {
          path: ACCOUNT_WALLET_PATH,
          title: polyglot.t("routes.wallet"),
          // No wallet_amount in browser title because the data used is in the /account query
          renderTitle: () =>
            `${polyglot.t("routes.wallet")} (${polyglot.toSmartCurrency(
              data.user?.total_wallet_amount
            )})`,
          subtitle: polyglot.t("routes.wallet_description"),
          LeftComponent: () => (
            <Icon.XLarge name="wallet" color={colors.primary} />
          ),
          View: Wallet,
        },
        ...(polyglot.country === "fr"
          ? [
              {
                path: ACCOUNT_CESU_PATH,
                title: polyglot.t("routes.cesu"),
                subtitle: polyglot.t("routes.cesu_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="ticket" color={colors.primary} />
                ),
                View: Cesu,
              },
            ]
          : []),
        {
          path: ACCOUNT_PAYMENT_METHODS_PATH,
          title: polyglot.t("routes.payment_methods"),
          subtitle: polyglot.t("routes.payment_methods_description"),
          LeftComponent: () => (
            <Icon.XLarge name="credit-card" color={colors.primary} />
          ),
          View: ManagePaymentMethods,
        },
        {
          path: ACCOUNT_NOTIFICATIONS_PATH,
          title: polyglot.t("routes.manage_notifications"),
          subtitle: polyglot.t("routes.manage_notifications_description"),
          LeftComponent: () => (
            <Icon.XLarge name="bell" color={colors.primary} />
          ),
          View: ManageNotifications,
        },
        {
          path: ACCOUNT_BILLING_PATH,
          title: polyglot.t("routes.billings"),
          subtitle: polyglot.t("routes.billings_description"),
          LeftComponent: () => (
            <Icon.XLarge name="document" color={colors.primary} />
          ),
          View: Billings,
        },
        ...(data.user?.is_a_company
          ? [
              {
                path: ACCOUNT_COMPANY_PATH,
                title: polyglot.t("routes.company"),
                subtitle: polyglot.t("routes.company_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="building" color={colors.primary} />
                ),
                View: Business,
              },
            ]
          : []),
      ],
    },
    {
      title: polyglot.t("account.products"),
      routes: [
        ...(data.monthly_pass_available
          ? [
              {
                title: polyglot.t("routes.pass"),
                renderTitle: () => (
                  <Block display="flex" alignItems="center" gap={spacings.xs}>
                    <span>{polyglot.t("routes.pass")}</span>
                    {data.membership &&
                      [
                        MEMBERSHIP_STATES.ACTIVE,
                        MEMBERSHIP_STATES.CANCELED,
                      ].includes(data.membership.state) && (
                        <div>
                          <Tag.Small kind={TAG.KIND.PRIMARY}>
                            {polyglot.t("common.enabled", { count: 0 })}
                          </Tag.Small>
                        </div>
                      )}
                  </Block>
                ),
                path: ACCOUNT_MEMBERSHIP_PATH,
                View: PassLanding,
                subtitle: polyglot.t("routes.pass_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="tag" color={colors.primary} />
                ),
                routes: [
                  {
                    path: ACCOUNT_MEMBERSHIP_MANAGE_PATH,
                    title: "Gérer mon abonnement",
                    View: ManageMembership,
                  },
                  {
                    path: ACCOUNT_MEMBERSHIP_CANCEL_PATH,
                    title: "Annuler mon abonnement",
                    View: CancelMembership,
                  },
                ],
              },
            ]
          : []),
        ...(polyglot.country === "fr"
          ? [
              {
                path: ACCOUNT_TAXES_PATH,
                title: polyglot.t("routes.taxes"),
                subtitle: polyglot.t("routes.taxes_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="document" color={colors.primary} />
                ),
                View: TaxesCredit,
              },
            ]
          : []),
        ...(data.referral_available
          ? [
              {
                title: polyglot.t("routes.referral"),
                path: REFERRAL_PATH,
                View: Referral,
                event: getReferralMenuClickEvent(),
                subtitle: polyglot.t("routes.referral_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="user-plus" color={colors.primary} />
                ),
              },
            ]
          : []),
        ...(data.vip_status_available
          ? [
              {
                href: public_pages.vip,
                title: polyglot.t("routes.vip_status"),
                subtitle: polyglot.t("routes.vip_status_description"),
                renderSubtitle: () => (
                  <Block>
                    {polyglot.t("routes.vip_status_description")}
                    <Block marginTop={spacings.xs}>
                      {data.user?.vip_status?.vip_active ? (
                        <Body14>
                          {polyglot.t("vip.active_until_date", {
                            date: formatDate(
                              data.user?.vip_status?.calendar_end_date,
                              "DD MMM YYYY"
                            ),
                          })}
                        </Body14>
                      ) : (
                        <Progress.Bar
                          css={`
                            &:before {
                              background-color: ${colors.purple};
                            }
                          `}
                          LeftComponent={() => (
                            <Body14 strong color={colors.purple}>
                              {data.user?.vip_status?.progress} %
                            </Body14>
                          )}
                          shape={PROGRESS.SHAPE.ROUND}
                          value={data.user?.vip_status?.progress}
                        />
                      )}
                    </Block>
                  </Block>
                ),
                LeftComponent: () => (
                  <Block
                    // same size as Icon XLARGE -> Layout alignement
                    width={sizes.size20}
                    height={sizes.size20}
                    display="flex"
                    alignItems="center"
                    // justifyContent="center"
                  >
                    <img src={VipBadge} alt={polyglot.t("routes.vip_status")} />
                  </Block>
                ),
              },
            ]
          : []),
        {
          href: public_pages?.gift_card,
          title: polyglot.t("routes.gift_card"),
          subtitle: polyglot.t("routes.gift_card_description"),
          LeftComponent: () => (
            <Icon.XLarge name="gift" color={colors.primary} />
          ),
        },
      ],
    },
    {
      title: polyglot.t("account.usefull_informations"),
      routes: [
        {
          href: public_pages?.faq,
          title: polyglot.t("routes.help_center"),
          subtitle: polyglot.t("routes.help_center_description"),
          LeftComponent: () => (
            <Icon.XLarge name="support" color={colors.primary} />
          ),
        },
        {
          path: TICKETS_PATH,
          title: polyglot.t("routes.tickets"),
          subtitle: polyglot.t("routes.tickets_description"),
          LeftComponent: () => (
            <Badge
              count={data?.total_unread_ticket_messages}
              position={BADGE.POSITION.TOP_RIGHT}
            >
              <Icon.XLarge name="envelope" color={colors.primary} />
            </Badge>
          ),
          View: Tickets,
        },
        ...(polyglot.country === "fr"
          ? [
              {
                href: public_pages?.tax_credit,
                title: polyglot.t("routes.tax_credit"),
                subtitle: polyglot.t("routes.tax_credit_description"),
                LeftComponent: () => (
                  <Icon.XLarge name="percent" color={colors.primary} />
                ),
              },
            ]
          : []),
        {
          href: public_pages?.insurance,
          title: polyglot.t("routes.insurance_alt"),
          subtitle: polyglot.t("routes.insurance_description_alt"),
          LeftComponent: () => (
            <Icon.XLarge name="shield-half" color={colors.primary} />
          ),
        },
        {
          path: ACCOUNT_BLOCKED_JOBBERS_PATH,
          title: polyglot.t("routes.blocked_jobbers"),
          subtitle: polyglot.t("routes.blocked_jobbers_description"),
          View: BlockedJobbers,
          LeftComponent: () => (
            <Icon.XLarge name="user-times" color={colors.primary} />
          ),
        },
        {
          href: public_pages?.trust_security,
          title: polyglot.t("routes.trust_and_security"),
          subtitle: polyglot.t("routes.trust_and_security_description"),
          LeftComponent: () => (
            <Icon.XLarge name="shield-check" color={colors.primary} />
          ),
        },
        {
          href: public_pages?.jobber_app,
          title: polyglot.t("routes.become_jobber"),
          subtitle: polyglot.t("routes.become_jobber_description"),
          LeftComponent: () => (
            <Icon.XLarge name="work" color={colors.primary} />
          ),
        },
      ],
    },
  ];
};

export default useAccountMenu;
