import React, { useState } from "react";
import styled from "styled-components";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Breadcrumb,
  Button,
  Col,
  Display3,
  Icon,
  InfiniteScrollEnhancer,
  Link,
  List,
  NoResult,
  Row,
  Tag,
  WalletCard,
} from "../../../components";
import {
  BUTTON,
  LIST,
  LIST_HEADER,
  TAG,
} from "../../../components/Styles/variants";
import WalletDetailsButton from "../../../components/dashboard/WalletDetailsButton";
import { useGetAccountWallet } from "../../../modules/routes/dashboard-routes";
import { formatDate } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { ACCOUNT_PATH } from "../config/routes";
import WalletSkeleton from "../skeletons/WalletSkeleton";
import RefundModal from "./RefundModal";
import WalletReferralItem from "./wallet/WalletReferralItem";
import WalletTransactionItem from "./wallet/WalletTransactionItem";

const StyledCesuWalletCard = styled(WalletCard)`
  background: ${colors.primary900};
`;

const CESU_IS_VISIBLE = polyglot.country === "fr";

const Wallet = () => {
  const getWallet = useGetAccountWallet();
  const [refundModalIsOpen, setRefundModalIsOpen] = useState(false);
  return (
    <>
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{polyglot.t("routes.wallet")}</Block>
        </Breadcrumb>
      </Block>
      <InfiniteScrollEnhancer query={getWallet} unionBy="transactions">
        {({ isLoading, data }) =>
          !isLoading ? (
            <>
              <Block marginBottom={spacings.ml}>
                <Display3>
                  {polyglot.toSmartCurrency(data.total_wallet_amount)}
                </Display3>
                <Body16>{polyglot.t("wallet.subtitle")}</Body16>
              </Block>
              <Row gutter={[spacings.s]} alignItems="stretch">
                <Col size={{ xs: 12, md: CESU_IS_VISIBLE ? 6 : 12 }}>
                  <RefundModal
                    amount={data.refundable_wallet_amount}
                    isOpen={refundModalIsOpen}
                    onClose={() => setRefundModalIsOpen(false)}
                  />
                  <WalletCard
                    name={polyglot.t("wallet.pot")}
                    icon="credit-card"
                    style={{ minHeight: "100%" }}
                    renderAmount={() => (
                      <Block width="100%" display="flex" alignItems="center">
                        <span>
                          {polyglot.toSmartCurrency(data.wallet_amount)}
                        </span>
                        <WalletDetailsButton
                          hide_fees_wallet={data.hide_fees_wallet}
                          refundable_wallet_amount={
                            data.refundable_wallet_amount
                          }
                          credited_wallet_amount={data.credited_wallet_amount}
                          fees_wallet_amount={data.fees_wallet_amount}
                        />
                      </Block>
                    )}
                    description={polyglot.t("wallet.pot_description")}
                    FooterComponent={() =>
                      !data.refund_in_progress ? (
                        <Button.Small
                          onClick={() => setRefundModalIsOpen(true)}
                          disabled={!data.can_ask_for_refund}
                          kind={BUTTON.KIND.MINIMAL}
                          shape={BUTTON.SHAPE.PILL}
                          numberOfLines={1}
                          RightComponent={() => (
                            <Icon.Medium name="arrow-right" />
                          )}
                        >
                          {polyglot.t("wallet.pot_refund")}
                        </Button.Small>
                      ) : (
                        <Button.Small
                          disabled
                          kind={BUTTON.KIND.MINIMAL}
                          shape={BUTTON.SHAPE.PILL}
                        >
                          {polyglot.t("wallet.pot_refund_in_progress")}
                        </Button.Small>
                      )
                    }
                  />
                </Col>
                {CESU_IS_VISIBLE && (
                  <Col size={{ xs: 12, md: 6 }}>
                    <StyledCesuWalletCard
                      name="CESU"
                      icon="ticket"
                      renderAmount={() => (
                        <Block
                          width="100%"
                          display="flex"
                          alignItems="center"
                          flexWrap="wrap"
                          justifyContent="space-between"
                        >
                          <span>
                            {polyglot.toSmartCurrency(data.cesu_wallet_amount)}
                          </span>
                          {data.cesu_amount_being_processed > 0 && (
                            <Block marginY={spacings.xs}>
                              <Tag.Small kind={TAG.KIND.MUTED}>
                                +
                                {polyglot.t("wallet.amount_waiting", {
                                  amount: polyglot.toSmartCurrency(
                                    data.cesu_amount_being_processed
                                  ),
                                })}
                              </Tag.Small>
                            </Block>
                          )}
                        </Block>
                      )}
                      style={{ minHeight: "100%" }}
                      description={polyglot.t("wallet.cesu_description")}
                      FooterComponent={() => (
                        <Button.Small
                          as={Link}
                          numberOfLines={1}
                          color={colors.body}
                          to="/account/cesu"
                          kind={BUTTON.KIND.MINIMAL}
                          shape={BUTTON.SHAPE.PILL}
                          LeftComponent={() => <Icon.Medium name="plus" />}
                        >
                          {polyglot.t("common.see_more")}
                        </Button.Small>
                      )}
                    />
                  </Col>
                )}
              </Row>
              <List.Header
                css={`
                  margin-top: ${spacings.s};
                `}
              >
                {polyglot.t("wallet.transactions_history")}
              </List.Header>
              {data.transactions.map((transaction, index, arr) => (
                <React.Fragment key={`transaction-${index}`}>
                  {transaction.date !== arr[index - 1]?.date && (
                    <Block marginTop={index !== 0 ? spacings.s : 0}>
                      <List.Header
                        kind={LIST_HEADER.KIND.SUBTITLE}
                        size={LIST.SIZE.COMPACT}
                        css="text-transform: capitalize;"
                      >
                        {formatDate(transaction.date, "dddd DD MMMM")}
                      </List.Header>
                    </Block>
                  )}
                  {transaction.type === "referral_reward" ? (
                    <WalletReferralItem {...transaction} />
                  ) : (
                    <WalletTransactionItem {...transaction} />
                  )}
                </React.Fragment>
              ))}
              {data.transactions?.length === 0 && (
                <NoResult
                  title={polyglot.t("wallet.no_transaction")}
                  subtitle={polyglot.t("wallet.no_transaction_description")}
                />
              )}
            </>
          ) : (
            <WalletSkeleton />
          )
        }
      </InfiniteScrollEnhancer>
    </>
  );
};

export default Wallet;
